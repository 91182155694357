import React, { useState, useEffect } from "react";
import {
    Button, Autocomplete, TextField, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, IconButton, ToggleButtonGroup, ToggleButton, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination, Select, MenuItem
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const ManagerInfo = (props) => {
    const user = props.user;
    const Employee = props.Employee;

    const [leademployees, setleadEmployees] = useState([]);
    const [selectedleadEmployee, setSelectedleadEmployee] = useState(null);
    const [selectedUnleadEmployee, setSelectedUnleadEmployee] = useState(null);
    const [projectData, setProjectData] = useState([]); // Initialize as an empty array
    const [reportType, setReportType] = useState('mastermind');
    const [openDialog, setOpenDialog] = useState(false);
    const [openUnassignDialog, setOpenUnassignDialog] = useState(false);
    const [unleadEmployees, setunleadEmployees] = useState([]);
    const [selectedReportDetail, setSelectedReportDetail] = useState('');

    const [mastermindPage, setMastermindPage] = useState(1); // Pagination starts from 1
    const [mastermindRowsPerPage, setMastermindRowsPerPage] = useState(10);
    const [mastermindTotalCount, setMastermindTotalCount] = useState(0);

    const [progressPage, setProgressPage] = useState(1); // Pagination starts from 1
    const [progressRowsPerPage, setProgressRowsPerPage] = useState(10);
    const [progressTotalCount, setProgressTotalCount] = useState(0);

    const mastermindTotalPages = Math.ceil(mastermindTotalCount / mastermindRowsPerPage);
    const progressTotalPages = Math.ceil(progressTotalCount / progressRowsPerPage);

    const handleMastermindPageChange = (event, newPage) => {
        setMastermindPage(newPage);
    };

    const handleProgressPageChange = (event, newPage) => {
        setProgressPage(newPage);
    };

    const handleMastermindDropdownChange = (event) => {
        setMastermindPage(event.target.value);
    };

    const handleProgressDropdownChange = (event) => {
        setProgressPage(event.target.value);
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await fetch('https://hr-backend-72v1.onrender.com/employees-without-manager');
                const data = await response.json();
                setleadEmployees(data);
            } catch (error) {
                console.error('Error fetching employee data:');
            }
        };

        fetchEmployees();
    }, []);

    const fetchUnderManagerEmployees = async () => {
        const email = Employee.email;
        try {
            const response = await fetch(`https://hr-backend-72v1.onrender.com/employees-under-manager?email=${email}`);
            const data = await response.json();
            // console.log("unlead Employees : ", data);
            setunleadEmployees(data);
        } catch (error) {
            console.error('Error fetching employee data:');
        }
    };

    useEffect(() => {
        fetchUnderManagerEmployees();
    }, []);

    const fetchMastermindData = async (page, rowsPerPage) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/getmanagerprojectdata', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: Employee.email,
                    page,
                    limit: rowsPerPage,
                }),
            });
            const data = await response.json();
            setProjectData(data.projects || []); // Ensure it's set to an array if undefined
            setMastermindTotalCount(data.totalCount || 0); // Handle the case when no data is returned
        } catch (error) {
            console.error('Error fetching mastermind report data:');
        }
    };

    const fetchProgressReportData = async (page, rowsPerPage) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/getprogressreportdata', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: Employee.email,
                    page,
                    limit: rowsPerPage,
                }),
            });
            const data = await response.json();
            setProjectData(data.progressReports || []); // Ensure it's set to an array if undefined
            setProgressTotalCount(data.totalCount || 0);
        } catch (error) {
            console.error('Error fetching progress report data:');
        }
    };

    useEffect(() => {
        if (reportType === 'mastermind') {
            fetchMastermindData(mastermindPage, mastermindRowsPerPage);
        } else {
            fetchProgressReportData(progressPage, progressRowsPerPage);
        }
    }, [reportType, mastermindPage, mastermindRowsPerPage, progressPage, progressRowsPerPage]);

    const handleReportTypeChange = (event, newType) => {
        setReportType(newType);
        if (newType === 'mastermind') {
            setMastermindPage(1);
        } else {
            setProgressPage(1);
        }
    };

    const handleOpenDialog = (reportDetail) => {
        setSelectedReportDetail(reportDetail);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedReportDetail('');
    };
    const handleOpenUnassignDialog = (reportDetail) => {
        setOpenUnassignDialog(true);
    };

    const handleCloseUnassignDialog = () => {
        setOpenUnassignDialog(false);
    };

    const approveManager = async (projectId) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/approve-report-manager', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectId }),
            });

            if (response.ok) {
                const updatedProject = await response.json();
                setProjectData(prevData =>
                    prevData.map(project =>
                        project._id === projectId
                            ? { ...project, approvedbymanager: true }
                            : project
                    )
                );
                alert('Project approved by manager');
            } else {
                alert('Failed to approve project detail');
            }
        } catch (error) {
            console.error('Error:');
        }
    };

    const approveHR = async (projectId) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/approve-report-hr', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectId }),
            });

            if (response.ok) {
                const updatedProject = await response.json();
                setProjectData(prevData =>
                    prevData.map(project =>
                        project._id === projectId
                            ? { ...project, approvedbyhr: true }
                            : project
                    )
                );
                alert('Project approved by HR');
            } else {
                alert('Failed to approve project detail');
            }
        } catch (error) {
            console.error('Error:');
        }
    };

    const assignEmployeeToManager = async () => {
        const isValidEmployee = leademployees.some(
            (employee) => employee.email === selectedleadEmployee?.email
        );

        if (!isValidEmployee) {
            alert("Please enter a valid option from the list.");
            return;
        }
        if (!selectedleadEmployee) {
            alert('Please select an employee');
            return;
        }

        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/assign-employee', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    managerEmail: Employee.email,
                    employeeName: selectedleadEmployee.name,
                    employeeEmail: selectedleadEmployee.email
                }),
            });

            if (response.ok) {
                alert('Employee assigned to manager successfully');
            } else {
                alert('Failed to assign employee');
            }
        } catch (error) {
            console.error('Error:');
        }
    };
    const unassignEmployee = async () => {
        const isValidEmployee = unleadEmployees.some(
            (employee) => employee.email === selectedUnleadEmployee?.email
        );

        if (!isValidEmployee) {
            alert("Please enter a valid option from the list.");
            return;
        }
        if (!selectedUnleadEmployee) {
            alert('Please select an employee');
            return;
        }

        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/unassign-employee', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    managerEmail: Employee.email,
                    employeeName: selectedUnleadEmployee.name,
                    employeeEmail: selectedUnleadEmployee.email
                }),
            });

            if (response.ok) {
                alert('Employee Unassigned');
                fetchUnderManagerEmployees();
                handleCloseUnassignDialog();
            } else {
                alert('Failed to Unassign employee');
            }
        } catch (error) {
            console.error('Error:');
        }
    };

    const promoteToManager = async (email, name) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/makemanager', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, name }),
            });

            if (response.ok) {
                // console.log('Employee promoted to Manager');
                alert('Employee promoted to Manager');
            } else {
                alert('Failed to promote Employee');
                // console.log('Failed to promote Employee');
            }
        } catch (error) {
            console.error('Error:');
        }
    };

    return (
        <div className="manager-container" style={{ margin: "20px" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h2 className="heading-xx-large">Manager Tab</h2>
                {(Employee.manager == 'yes') &&
                    <Button
                        variant="outlined"
                        sx={{
                            marginRight: "40px",
                            borderRadius: "10px"
                        }}
                        onClick={handleOpenUnassignDialog}
                    >
                        Unassign Employee
                    </Button>
                }
            </div>

            <hr />
            <div style={{ width: "95%", margin: "auto" }}>
                {(Employee.manager == 'no' && user.role == 'HR') &&
                    <div style={{ display: "flex", flexDirection: "column", gap: "30px", marginTop: "30px" }}>
                        <h1 className="heading">Employee is Not A Manager Right Now !</h1>
                        <Button
                            variant="outlined"
                            onClick={() => promoteToManager(Employee.email, Employee.name)}
                        >
                            Promote to Manager
                        </Button>
                    </div>
                }
                {Employee.manager == 'yes' &&
                    <>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            <Autocomplete style={{ width: "30%" }}
                                options={leademployees}
                                getOptionLabel={(option) => `${option.name} (${option.email})`}
                                renderInput={(params) => <TextField {...params} label="Assign New Employee" variant="standard" />}
                                onChange={(event, newValue) => setSelectedleadEmployee(newValue)}
                            />
                            <Button style={{ borderRadius: "50%" }} variant="contained" onClick={assignEmployeeToManager}>Assign</Button>
                        </div>
                        <hr />
                        <div>
                            <ToggleButtonGroup
                                value={reportType}
                                exclusive
                                onChange={handleReportTypeChange}
                                aria-label="report type"
                            >
                                <ToggleButton value="mastermind">Mastermind Report</ToggleButton>
                                <ToggleButton value="progress">Progress Report</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                            {reportType === 'mastermind' && projectData.length > 0 && (
                                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Appointments</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Pipelines</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>HR Approval</TableCell>
                                                <TableCell>Manager Approval</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {projectData.map((project) => (
                                                <TableRow key={project._id}>
                                                    <TableCell>{project.name}</TableCell>
                                                    <TableCell>{project.appointmentsTaken}</TableCell>
                                                    <TableCell>{project.email}</TableCell>
                                                    <TableCell sx={{ color: project.pipelineMoved === 'yes' ? '#4484FF' : 'red' }}>
                                                        {project.pipelineMoved}
                                                    </TableCell>
                                                    <TableCell>{new Date(project.date).toLocaleDateString()}</TableCell>
                                                    <TableCell>{project.approvedbyhr ? 'Yes' : (
                                                        user.role === 'HR' && project.approvedbymanager ? (
                                                            <IconButton onClick={() => approveHR(project._id)}>
                                                                <CheckIcon />
                                                            </IconButton>
                                                        ) : 'No'
                                                    )}</TableCell>
                                                    <TableCell>{project.approvedbymanager ? 'Yes' : (
                                                        user.role !== 'HR' ? (
                                                            <IconButton onClick={() => approveManager(project._id)}>
                                                                <CheckIcon />
                                                            </IconButton>
                                                        ) : 'No'
                                                    )}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {/* Pagination Component for Mastermind */}
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", margin: "20px 0" }}>
                                        <Pagination
                                            count={mastermindTotalPages}
                                            page={mastermindPage}
                                            onChange={handleMastermindPageChange}
                                            color="primary"
                                        />
                                        <Select
                                            value={mastermindPage}
                                            onChange={handleMastermindDropdownChange}
                                            sx={{ minWidth: 120 }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: { maxHeight: 300 },
                                                },
                                            }}
                                        >
                                            {Array.from({ length: mastermindTotalPages }, (_, index) => (
                                                <MenuItem key={index + 1} value={index + 1}>
                                                    Page {index + 1}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </TableContainer>
                            )}

                            {reportType === 'progress' && projectData.length > 0 && (
                                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Report Detail</TableCell>
                                                <TableCell>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {projectData.map((report) => (
                                                <TableRow key={report._id}>
                                                    <TableCell>{report.name}</TableCell>
                                                    <TableCell>{report.email}</TableCell>
                                                    <TableCell>
                                                        <Button variant="outlined" onClick={() => handleOpenDialog(report.reportDetail)}>
                                                            View Report
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>{new Date(report.date).toLocaleDateString()}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {/* Pagination Component for Progress Report */}
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", margin: "20px 0" }}>
                                        <Pagination
                                            count={progressTotalPages}
                                            page={progressPage}
                                            onChange={handleProgressPageChange}
                                            color="primary"
                                        />
                                        <Select
                                            value={progressPage}
                                            onChange={handleProgressDropdownChange}
                                            sx={{ minWidth: 120 }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: { maxHeight: 300 },
                                                },
                                            }}
                                        >
                                            {Array.from({ length: progressTotalPages }, (_, index) => (
                                                <MenuItem key={index + 1} value={index + 1}>
                                                    Page {index + 1}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </TableContainer>
                            )}

                            <Dialog open={openDialog} onClose={handleCloseDialog}>
                                <DialogTitle>Report Detail</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>{selectedReportDetail}</DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog}>Close</Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={openUnassignDialog} onClose={handleCloseUnassignDialog}>
                                <DialogTitle>Unassign Employee</DialogTitle>
                                <DialogContent>
                                    <Autocomplete
                                        options={unleadEmployees}
                                        getOptionLabel={(option) => `${option.name} (${option.email})`}
                                        renderInput={(params) => <TextField {...params} label="Unassign Employee" variant="standard" />}
                                        onChange={(event, newValue) => setSelectedUnleadEmployee(newValue)}
                                        sx={{ width: '100%' }}  // Optional if you want the Autocomplete to stretch fully inside DialogContent
                                    />
                                    <Button variant="contained" onClick={unassignEmployee} style={{ marginTop: 16 }}>Unassign</Button>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseUnassignDialog}>Close</Button>
                                </DialogActions>
                            </Dialog>


                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default ManagerInfo;
