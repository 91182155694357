import React from "react";
import { Box, Typography, Button, Paper, Fade } from "@mui/material";
import { styled } from "@mui/system";
import { FiBell, FiX } from "react-icons/fi";

const NotificationCard = styled(Paper)(({ theme }) => ({
  position: "fixed", // Make it fixed
  top: "10px", // Position at the top
  left: "40%", // Center horizontally, 40% from left
  transform: "translateX(-50%)", // Center alignment adjustment
  padding: "16px",
  maxWidth: "600px",
  transition: "all 0.3s ease-in-out",
  background: "linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  zIndex: 1300, // Ensure it appears above other components
  "&:hover": {
    transform: "translate(-50%, -2px)",
    boxShadow: "0 6px 25px rgba(0, 0, 0, 0.12)"
  },
  [theme.breakpoints.down("sm")]: {
    left: "50%", // Center on smaller screens
    transform: "translate(-50%, 0)",
    margin: "16px"
  }
}));

const ContentWrapper = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "16px",
});

const IconWrapper = styled(Box)({
  padding: "8px",
  borderRadius: "50%",
  backgroundColor: "#e3f2fd",
  color: "#1976d2",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const CloseButton = styled(Button)({
  position: "absolute",
  top: "8px",
  right: "8px",
  minWidth: "auto",
  padding: "4px",
  color: "#64748b",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  }
});

const ActionButton = styled(Button)({
  marginTop: "16px",
  backgroundColor: "#1976d2",
  color: "#ffffff",
  textTransform: "none",
  padding: "8px 24px",
  "&:hover": {
    backgroundColor: "#1565c0"
  }
});

const NotificationComponent = ({ alertMessage, showAlert, handleCloseAlert }) => {
  if (!showAlert) return null;

  return (
    <Fade in={showAlert}>
      <NotificationCard role="alert" aria-live="polite">
        <CloseButton onClick={handleCloseAlert} aria-label="Close notification">
          <FiX size={20} />
        </CloseButton>

        <ContentWrapper>
          <IconWrapper>
            <FiBell size={24} />
          </IconWrapper>

          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" component="h2" sx={{ color: "#1e293b", mb: 1 }}>
              Inactivity Alert
            </Typography>

            <Typography variant="body1" sx={{ color: "#475569", mb: 1 }}>
              {alertMessage}
            </Typography>

            <Typography variant="caption" sx={{ color: "#64748b", display: "block", mb: 2 }}>
              {new Date().toLocaleTimeString()}
            </Typography>

            <ActionButton variant="contained" onClick={handleCloseAlert} aria-label="Acknowledge alert">
              Got it
            </ActionButton>
          </Box>
        </ContentWrapper>
      </NotificationCard>
    </Fade>
  );
};

export default NotificationComponent;
