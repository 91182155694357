import React, { useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Button,
  TextField,
  Typography,
  Alert
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import '../AddEmployee/AddEmployee.css'

const NewProject = ({ onBack }) => {
  const [projectName, setProjectName] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [projectType, setProjectType] = useState("");
  const [description, setDescription] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [messageType, setMessageType] = useState('success'); 
  const [message, setMessage] = useState(null);



  const handleProjectName = (event) => setProjectName(event.target.value);
  const handleStartDate = (date) => setStartDate(date);
  const handleProjectType = (event) => setProjectType(event.target.value);
  const handleDescription = (event) => setDescription(event.target.value);

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      // console.log(projectName, startDate, projectType, description);
      // console.log(projectName, startDate, projectType, description);
      const response = await fetch("https://hr-backend-72v1.onrender.com/addproject", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: projectName,
          start_date: startDate.toISOString(),
          type: projectType,
          description: description,
        }),
      });

      if (response.ok) {
        setMessage("Project created successfully");
        setMessageType('success');

      } else {
        setMessage("Error creating project");
        setMessageType('error');

      }
    } catch (error) {
      // console.error("Error:", error);
      setMessage("Error creating project");
      setMessageType('error');

    }
    setIsProcessing(false);
  };

  return (
    <div>
      <Button sx={{ border: "2px solid" }} onClick={onBack}>
        <ArrowBackIosNewIcon fontSize="large" />
      </Button>
      <h4
        className="myTableHeader animate__animated animate__lightSpeedInLeft"
        style={{ paddingLeft: "5%" }}
      >
        Create New Project..
      </h4>
      <div className="form">
        {message && (
          <Alert severity={messageType} style={{ marginBottom: "20px" }}>
            {message}
          </Alert>
        )}
        <div style={{ display: "flex", gap: "100px" }}>
          <TextField
            required
            value={projectName}
            onChange={handleProjectName}
            label="Project Name"
            fullWidth
            variant="standard"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDate}
              renderInput={(params) => <TextField {...params} fullWidth variant="standard" />}
            />
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex", gap: "100px" }}>
          <TextField
            required
            value={projectType}
            onChange={handleProjectType}
            label="Project Type"
            fullWidth
            variant="standard"
          />
          <TextField
            multiline
            value={description}
            onChange={handleDescription}
            label="Description"
            fullWidth
            variant="standard"
          />
        </div>
      </div>
      <Button onClick={handleSubmit} disabled={isProcessing}>
        Create Project
      </Button>
    </div>
  );
};

export default NewProject;
