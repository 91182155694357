import React, { useState, useEffect } from "react";
import "./PastTimers.css";
import "../SearchContainer.css";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  Pagination,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import dayjs from "dayjs";

const PastTimers = ({ pastTimersToggle }) => {
  const [pastTimers, setPastTimers] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchDate, setSearchDate] = useState(dayjs());
  const [filteredReports, setfilteredReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalTimers, setTotalTimers] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10); // Number of records per page
  const [searchMode, setSearchMode] = useState("name"); // 'name' or 'date'

  const fetchTimers = async () => {
    setLoading(true);
    const queryParam =
      searchMode === "name"
        ? `name=${searchName}`
        : `date=${searchDate.format("YYYY-MM-DD")}`;
    try {
      const response = await fetch(
        `https://hr-backend-72v1.onrender.com/getAllTimers?${queryParam}&page=${currentPage}`
      );
      if (!response.ok) throw new Error("Failed to fetch");
      const data = await response.json();
      setPastTimers(data.timers);
      setTotalPages(Math.ceil(data.totalTimers / limit)); // Set total pages
    } catch (error) {
      console.error("Error fetching timer data:");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pastTimersToggle) {
      fetchTimers(); // Fetch timers for the current page
    }
  }, [pastTimersToggle, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page when pagination changes
  };

  const handleDropdownChange = (event) => {
    setCurrentPage(event.target.value); // Update current page from dropdown
  };

  const handleSearchByName = (event) => {
    setSearchName(event.target.value);
  };

  const handleSearchByDate = (date) => {
    setSearchDate(date);
  };

  const handleSearchModeChange = (event, newMode) => {
    if (newMode !== null) {
      setSearchMode(newMode);
    }
  };

  return (
    <div>
      <div
        className="search-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="night">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>

        <div
          className="search-fields"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={searchMode}
            exclusive
            onChange={handleSearchModeChange}
            sx={{
              "& .MuiToggleButtonGroup-grouped": {
                margin: "8px", // Margin between buttons
                border: 1, // Removes default border
                borderColor: "white",
                color: "white",
                "&.Mui-selected": {
                  color: "#ffad3d",
                  borderColor: "#ffad3d",
                  backgroundColor: "transparent",
                },
                "&:not(.Mui-selected)": {
                  color: "white",
                  borderColor: "white",
                },
                "&:hover": {
                  backgroundColor: "rgba(255, 173, 61, 0.1)",
                  borderColor: "#ffad3d",
                  color: "#ffad3d",
                },
              },
            }}
          >
            <ToggleButton value="name">Search By Name</ToggleButton>
            <ToggleButton value="date">Search By Date</ToggleButton>
          </ToggleButtonGroup>
          {searchMode === "name" ? (
            <TextField
              sx={{
                backgroundColor: "#4484FF",
                width: "90%",
                borderRadius: "10px",
                "& .MuiInputLabel-root": { color: "#ffad3d" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ffad3d" },
                  "&:hover fieldset": { borderColor: "#ffad3d" },
                  "&.Mui-focused fieldset": { borderColor: "#ffad3d" },
                  "& input": { color: "#ffad3d" },
                },
              }}
              value={searchName}
              onChange={handleSearchByName}
              label="Search By Name"
              variant="outlined"
            />
          ) : (
            <TextField
              label="Search By Date"
              type="date"
              sx={{
                backgroundColor: "#4484FF",
                width: "90%",
                borderRadius: "10px",
                "& .MuiInputLabel-root": { color: "#ffad3d" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ffad3d" },
                  "&:hover fieldset": { borderColor: "#ffad3d" },
                  "&.Mui-focused fieldset": { borderColor: "#ffad3d" },
                  "& input": { color: "#ffad3d" },
                },
              }}
              InputLabelProps={{ shrink: true }}
              value={searchDate.format("YYYY-MM-DD")}
              onChange={(e) => handleSearchByDate(dayjs(e.target.value))}
            />
          )}
          <Button
            onClick={fetchTimers}
            variant="outlined"
            sx={{
              color: "#ffad3d",
              width: "150px",
              borderColor: "#ffad3d",
              "&:hover": {
                borderColor: "#ffad3d", // Keeps the border color on hover
                backgroundColor: "rgba(255, 173, 61, 0.08)", // Light background on hover
              },
            }}
          >
            Find
          </Button>
        </div>
      </div>

      <TableContainer component={Paper} className="timerContainer">
        <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
          PAST TIMERS
        </h4>
        <Table sx={{ minWidth: 650 }} className="leaveTable">
          <TableHead>
            <TableRow>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Name</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Email</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Date</span>
              </TableCell>
              <TableCell className="myTableHeadCells" align="right">
                <span className="myTableHeadSpan">Timer</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredReports.length > 0 ? filteredReports : pastTimers).map(
              (timer) => (
                <TableRow key={timer._id}>
                  <TableCell sx={{ fontSize: "large" }}>{timer.name}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>
                    {timer.email}
                  </TableCell>
                  <TableCell sx={{ fontSize: "large" }}>
                    {new Date(timer.date).toLocaleString()}
                  </TableCell>
                  <TableCell className="timerCell" align="right">
                    {timer.time}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            margin: "20px 0",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />

          <Select
            value={currentPage}
            onChange={handleDropdownChange}
            sx={{ minWidth: 120 }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300, // Maximum height of dropdown
                },
              },
            }}
          >
            {Array.from({ length: totalPages }, (_, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                Page {index + 1}
              </MenuItem>
            ))}
          </Select>
        </div>
      </TableContainer>
    </div>
  );
};

export default PastTimers;
