// Almost All of the content is same from HR Component ... Most of the files imported are from that Component

import React, { useState, useEffect, useRef } from "react";

import StartIcon from '../../utilities/start.svg';
import PauseIcon from '../../utilities/pause.svg';
import ResumeIcon from '../../utilities/resume.svg';
import StopIcon from '../../utilities/stop.svg';

import { Alert, Snackbar } from "@mui/material";

import AddEmployee from "../HR/AddEmployee/addEmployee";
import Requests from "../HR/Requests/requestedLeaves";
import PastTimers from "../HR/pastTimers/pastTimers";
import ProgressReports from "../HR/Show Progress Reports/progressReports";
import Profile from "../HR/Profiles/profiles";
import Navbar from "../Navbar/navbar";
import RunningTimers from "../HR/currentTimers/timers";
import RequestLeave from "../HR/Request A Leave/RequestLeave";
import Countdown from "../HR/My Countdown/Countdown";

import "../HR/hr.css";
import Sidebar from "./Sidebar/sidebar";
import Dashboard from "../HR/Dashboard/Dashboard";
import Events from "../HR/Events/Events";
import Mastermind from "../HR/Projects/Mastermind";
import Projects from "../HR/Projects/Projects";


const Executive = (props) => {
  const user = props.user;

  const [dashbaordToggle, setDashbaordToggle] = useState(true);
  const [addEmployeeToggle, setAddEmployeeToggle] = useState(false);
  const [progressReportsToggle, setProgressReportsToggle] = useState(false);
  const [requestedLeavesToggle, setRequestedLeavesToggle] = useState(false);
  const [pastTimersToggle, setPastTimersToggle] = useState(false);
  const [projectsToggle, setProjectsToggle] = useState(false);
  const [employeeDetailsToggle, setEmployeeDetailsToggle] = useState(false);

  const [eventsToggle, setEventsToggle] = useState(false);
//   const [myCountdownToggle, setMyCountdownToggle] = useState(false);
//   const [requestALeaveToggle, setRequestALeaveToggle] = useState(false);

//   useEffect(() => {//
//     const loginEmployee = async () => {
//       try {
//         const response = await fetch(`https://hr-backend-72v1.onrender.com/loginemployee?email=${user.email}`);
//         const data = await response.json();

//         if (response.ok) {
//           console.log('Login successful:', data);
//           setEmployee(data);
//         } else {
//           console.error('Login failed:', data.message);
//         }
//       } catch (error) {
//         console.error('Error during login:', error);
//       }
//     };

//     loginEmployee();
//   }, [user.email]);

  const dashbaordToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(true);

    setEventsToggle(false);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(false);
  };
  const handleOpenAddEmployee = () => {
    setAddEmployeeToggle(true);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);

    setEventsToggle(false);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(false);
  }
  const handleCloseAddEmployee = () => setAddEmployeeToggle(false);

  const progressReportsToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(true);
    setDashbaordToggle(false);
    setEventsToggle(false);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(false);
  };
  const requestedLeavesToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(true);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(false);
  };
  const pastTimersToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(true);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(false);
  };
  const projectsToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(true);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(false);
  };
  const employeeDetailsToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(true);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(false);
  };
  const eventsToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(true);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(false);
  };
  const myCountdownToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    // setMyCountdownToggle(true);
    // setRequestALeaveToggle(false);
  };
  const requestALeaveToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    // setMyCountdownToggle(false);
    // setRequestALeaveToggle(true);
  };

  

  return (
    <div className="hr">
      <Sidebar
        onDashboard={dashbaordToggleHandler}
        onAddEmployee={handleOpenAddEmployee}
        onShowLeaves={requestedLeavesToggleHandler}
        onShowTimers={pastTimersToggleHandler}
        onShowProgress={progressReportsToggleHandler}
        onShowProjectA={projectsToggleHandler}
        onShowProfile={employeeDetailsToggleHandler}
        onShowEvents={eventsToggleHandler}
        // onMyCountdown={myCountdownToggleHandler}
        // onRequestALeave={requestALeaveToggleHandler}
        dashbaordToggle={dashbaordToggle}
        addEmployeeToggle={addEmployeeToggle}
        progressReportsToggle={progressReportsToggle}
        requestedLeavesToggle={requestedLeavesToggle}
        pastTimersToggle={pastTimersToggle}
        projectsToggle={projectsToggle}
        employeeDetailsToggle={employeeDetailsToggle}
        eventsToggle={eventsToggle}
        // myCountdownToggle={myCountdownToggle}
        // requestALeaveToggle={requestALeaveToggle}
      />

      <div className="content">
        <Navbar user={user} />
        {dashbaordToggle && <Dashboard user={user} />}
        {addEmployeeToggle && (
          <AddEmployee
            addEmployeeToggle={addEmployeeToggle}

          />
        )}
        {progressReportsToggle && (
          <ProgressReports progressReportsToggle={progressReportsToggle} />
        )}
        {requestedLeavesToggle && (
          <Requests requestedLeavesToggle={requestedLeavesToggle} user={user}/>
        )}
        {pastTimersToggle && <PastTimers pastTimersToggle={pastTimersToggle} />}
        {projectsToggle && <Projects projectsToggle={projectsToggle} />}
        {employeeDetailsToggle && (
          <Profile user={user} employeeDetailsToggle={employeeDetailsToggle} />
        )}
        {eventsToggle && <Events user={user} />}
        {/* {myCountdownToggle &&
          <div className="timer">
            <h1>Helloooo</h1>
          </div>
        }
        {requestALeaveToggle && <RequestLeave />} */}

      </div>
      
    </div>
  );
};

export default Executive;
