import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // First, register the service worker
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker initially registered:', registration);

        // Unregister the service worker after initial registration
        return registration.unregister().then((boolean) => {
          console.log(`Service Worker unregistered: ${boolean}`);

          // Register the service worker again after unregistering
          return navigator.serviceWorker.register('/service-worker.js')
            .then((newRegistration) => {
              console.log('Service Worker re-registered:', newRegistration);
            })
            .catch((error) => {
              console.error('Service Worker re-registration failed:', error);
            });
        });
      })
      .catch((error) => {
        console.error('Initial Service Worker registration failed:', error);
      });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
