import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Alert, Snackbar } from '@mui/material';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import './RequestedChanges.css'

const RequestedChanges = (props) => {
    const [requests, setRequests] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const email = props.Employee.email;
    const user = props.user ? props.user : null;

    const fetchRequests = async () => {
        try {
            const response = await fetch(`https://hr-backend-72v1.onrender.com/getRequestedChanges/${email}`);
            const data = await response.json();
            if (data.success) {
                setRequests(data.requests);
            } else {
                console.error('Error fetching requested changes');
            }
        } catch (error) {
            console.error('Error fetching requested changes:');
        }
    };
    useEffect(() => {

        fetchRequests();
    }, []);


    // Approve a request
    const approveRequest = async (request) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/approveRequest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: request._id }),
            });
            const data = await response.json();
            if (data.success) {
                setAlertMessage('Request accepted');
                setShowAlert(true);
                fetchRequests(); // Refresh the table after approval
            } else {
                alert('Error approving request');
            }
        } catch (error) {
            console.error('Error approving request:');
        }
    };

    // Reject a request (delete)
    const rejectRequest = async (id) => {
        try {
            const response = await fetch(`https://hr-backend-72v1.onrender.com/rejectRequest/${id}`, {
                method: 'DELETE',
            });
            const data = await response.json();
            if (data.success) {
                setAlertMessage('Request rejected');
                setShowAlert(true);
                fetchRequests(); // Refresh the table after rejection
            } else {
                alert('Error rejecting request');
            }
        } catch (error) {
            console.error('Error rejecting request:');
        }
    };

    // Handle alert close
    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <div className="requestedChangeContainer">
            <h2 className="heading-xx-large">Requested Changes</h2>
            <hr />
            <div className='requestedChange'>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className='myTableHeadCells'>Name</TableCell>
                                <TableCell className='myTableHeadCells'>Field</TableCell>
                                <TableCell className='myTableHeadCells'>Requested Change</TableCell>
                                <TableCell className='myTableHeadCells'>Status</TableCell>
                                {(user.role === 'HR' || user.role === 'Executive') && <TableCell className='myTableHeadCells'>Actions</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requests.map((request) => (
                                <TableRow key={request._id}>
                                    <TableCell>{request.name}</TableCell>
                                    <TableCell>{request.field}</TableCell>
                                    <TableCell>{request.value}</TableCell>
                                    {request.status == 'pending' ?
                                        (<TableCell><span className='pending'>{request.status}</span></TableCell>)
                                        :
                                        (<TableCell><span className='accepted'>{request.status}</span></TableCell>)
                                    }

                                    {/* <TableCell>{request.status == 'pending' ?
                                    ()
                                        :
                                        ()    
                                }</TableCell> */}
                                    {(user.role === 'HR' || user.role === 'Executive') && (
                                        <TableCell>
                                            <div style={{ display: 'flex', flexDirection: "row", gap: "10px" }}>
                                                {request.status === 'pending' ? (
                                                    <>
                                                        <IconButton
                                                            color="success"
                                                            onClick={() => approveRequest(request)}
                                                        >
                                                            <DoneIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => rejectRequest(request._id)}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    'Already Taken'
                                                )}
                                            </div>
                                        </TableCell>
                                    )}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Snackbar for alert */}
                <Snackbar
                    open={showAlert}
                    autoHideDuration={5000}  // Alert disappears after 4 seconds
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseAlert} severity={alertMessage.includes('accepted') ? 'success' : 'error'}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
};

export default RequestedChanges;
