import React, { useState, useEffect, useRef } from "react";
import PersonalInfo from './Personal Info/PersonalInfo';
import SalaryInfo from './Salary Info/SalaryInfo';
import Documents from './documents Info/Documents';
import ManagerInfo from './Manager Info/ManagerInfo';
import RequestedChanges from './Requested Changes/RequestedChanges';
import GoBack from '../../../utilities/goback-png.png';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DualBall from '../../../utilities/dual ball back.svg';
import { CircularProgress } from "@mui/material";

import {
  Button,
  // DialogActions,
  // DialogContent,
  // TableContainer,
  TextField,
  // Box,
  // InputLabel,
  // MenuItem,
  // FormControl,
  // Select,
  // Table,
  // TableBody,
  // TableCell,
  // TableHead,
  // TableRow,
  // Paper,
  // Dialog,
} from '@mui/material';

import "./personalDetails.css";




const PersonalDetails = (props) => {
  const user = props.user;
  const [showPersonal, setShowPersonal] = useState(true);
  const [showSalary, setShowSalary] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showManager, setshowManager] = useState(false);
  const [showRequestedChanges, setshowRequestedChanges] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadbtnshow, setuploadbtnshow] = useState(false);
  const [loader, showLoader] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);



  const [toggleEditE, settoggleEditE] = useState(false);
  const [toggleEditP, settoggleEditP] = useState(false);
  const [toggleEditA, settoggleEditA] = useState(false);
  const [toggleEditB, settoggleEditB] = useState(false);

  const Employee = props.Employee;
  const personalClass = showPersonal ? 'activeNav' : 'deactivatedNav';
  const salaryClass = showSalary ? 'activeNav' : 'deactivatedNav';
  const documentClass = showDocuments ? 'activeNav' : 'deactivatedNav';
  const managerClass = showManager ? 'activeNav' : 'deactivatedNav';
  const requestsClass = showRequestedChanges ? 'activeNav' : 'deactivatedNav';

  useEffect(() => {
    // Wait until Employee.profilepic is available before loading
    if (Employee && Employee.profilepic) {
      setIsImageLoading(false);
    }
  }, [Employee]);

  const handleImageLoad = () => {
    setIsImageLoading(false); // Image finished loading
  };

  const handleProfilePictureUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setuploadbtnshow(true);
      setSelectedFile(file);
      const objectURL = URL.createObjectURL(file);
      setProfilePicture(objectURL);
    }
  };

  const handleUploadPhoto = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('photo', selectedFile);
    formData.append('email', Employee.email);
    formData.append('name', Employee.name);
    formData.append('field', 'profilepic');
    showLoader(true);
    setuploadbtnshow(false);

    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/uploadphoto', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (response.ok && data.success) {
        showLoader(false);
        // console.log('Photo uploaded successfully:', data);
        // console.log('Photo uploaded successfully:', data);
      } else {
        setuploadbtnshow(true);
        // console.error('Photo upload failed:', data.message);
      }
    } catch (error) {
      setuploadbtnshow(true);
      // console.error('Error uploading photo:', error);
    }
  };

  const handleShowPersonalD = () => {
    setShowPersonal(true);
    setShowSalary(false);
    setShowDocuments(false);
    setshowManager(false);
    setshowRequestedChanges(false);
  }
  const handleShowSalaryInfo = () => {
    setShowPersonal(false);
    setShowSalary(true);
    setShowDocuments(false);
    setshowManager(false);
    setshowRequestedChanges(false);
  }
  const handleShowDocuments = () => {
    setShowPersonal(false);
    setShowSalary(false);
    setShowDocuments(true);
    setshowManager(false);
    setshowRequestedChanges(false);
  }
  const handleShowManager = () => {
    setShowPersonal(false);
    setShowSalary(false);
    setShowDocuments(false);
    setshowManager(true);
    setshowRequestedChanges(false);
  }
  const handleShowRequests = () => {
    setShowPersonal(false);
    setShowSalary(false);
    setShowDocuments(false);
    setshowManager(false);
    setshowRequestedChanges(true);
  }

  function generateThumbnailUrl(driveUrl) {
    const fileIdMatch = driveUrl.match(/d\/([a-zA-Z0-9_-]+)/);
    if (fileIdMatch) {
      const fileId = fileIdMatch[1];
      // console.log("fileid : ", fileId);
      // console.log("fileid : ", fileId);
      return `https://drive.google.com/thumbnail?id=${fileId}&sz=s256`;
    }
    return driveUrl; // Fallback to original URL if not matching pattern
  }





  // if (!Employee) {
  //   return <div>Loading...</div>;
  // }


  return (
    <div>
      <div className="personalD">
        <div className="picture-header">
          {(user.role == 'HR' || user.role == 'Executive') &&
            <div style={{ textAlign: "left", alignContent: "left", marginTop: "20px", marginLeft: "5%" }} className="go-back-img">
              <Button
                className="backButton"
                sx={{ border: "2px solid" }}
                onClick={props.handleDontShowProfile}
              >
                <ArrowBackIosNewIcon fontSize="large" />
              </Button>
            </div>
          }

          {/* <img onClick={props.handleDontShowProfile} src={GoBack} alt="Go Back Icon" width="80" height="80" /> */}


        </div>
        <div className="profile-picture"
          style={{
            width: "500px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto"
          }}>
          <label htmlFor="upload-photo">
            <input
              type="file"
              id="upload-photo"
              style={{ display: 'none' }}
              onChange={handleProfilePictureUpload}
            />
            {isImageLoading ? (
              // Show a spinner while loading the profile picture
              <CircularProgress />
            ) : (
              <>
                {profilePicture ? (
                  <img
                    src={profilePicture}
                    alt="Profile"
                    width="250"
                    height="250"
                    style={{
                      borderRadius: "50%",
                      cursor: "pointer",
                      objectFit: "cover", // This makes sure the image covers the area without distortion
                      objectPosition: "center", // This centers the image inside the container
                    }}
                    onLoad={handleImageLoad}
                  />
                ) : (
                  <img
                    src={Employee.profilepic}
                    alt="Profile"
                    width="250"
                    height="250"
                    style={{
                      borderRadius: "50%",
                      cursor: "pointer",
                      objectFit: "cover", // This makes sure the image covers the area without distortion
                      objectPosition: "center", // This centers the image inside the container
                    }}
                    onLoad={handleImageLoad} // Ensure image has loaded
                  />
                )}
              </>
            )}


          </label>

          {uploadbtnshow && (
            <Button
              variant="outlined"
              sx={{ marginLeft: "50px", padding: "10px 20px" }}
              onClick={handleUploadPhoto}
            >
              Upload Photo
            </Button>
          )}
          {loader && <img sx={{ marginLeft: "50px" }} className='dual-ball' src={DualBall} alt="Dual Ball" width="70" height="70" />}
        </div>
        <div className="main-header">

          <div className="emp-name-pos">
            <h3 className="emp-name">{Employee ? Employee.name : ""}</h3>
            <p className="emp-pos">{Employee ? Employee.pos : ""}</p>
          </div>
          <div className="toggle-head">
            <button onClick={handleShowPersonalD} className={`salary toggle-btn ${personalClass}`}>
              Personal
            </button>
            <button onClick={handleShowSalaryInfo} className={`personal toggle-btn ${salaryClass}`}>
              Salary
            </button>
            <button onClick={handleShowDocuments} className={`documents toggle-btn ${documentClass}`}>
              Documents
            </button>
            <button onClick={handleShowManager} className={`management toggle-btn ${managerClass}`}>
              Manager tab
            </button>


            <button onClick={handleShowRequests} className={`documents toggle-btn ${requestsClass}`}>
              Requested Changes
            </button>



          </div>
        </div>
        {showPersonal &&
          <div className="personalInfo">
            <PersonalInfo
              user={props.user}
              Employee={props.Employee}
              inputEditHandler={props.inputEditHandler} />
          </div>
        }

        {showSalary &&
          <div className="SalaryInfo">
            <SalaryInfo
              user={props.user}
              Employee={props.Employee}
              inputEditHandler={props.inputEditHandler} />
          </div>
        }
        {showDocuments &&
          <div className="documents">
            <Documents
              user={props.user}
              Employee={props.Employee} />
          </div>
        }
        {showManager &&
          <div className="manager">
            <ManagerInfo
              user={props.user}
              Employee={props.Employee}
            />
          </div>
        }
        {showRequestedChanges &&
          <div className="requestedchanges">
            <RequestedChanges
              user={props.user}
              Employee={props.Employee}
            />
          </div>
        }

      </div>
    </div>
  );
};

export default PersonalDetails;
