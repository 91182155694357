import React, { useState, useEffect } from "react";
import "./ProgressReport.css";
import '../SearchContainer.css';

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  TableContainer,
  Box,
  TextField,
  Select, MenuItem,
  ToggleButtonGroup,
  ToggleButton
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../../utilities/logo-png.png";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ProgressRep = ({ progressReportsToggle }) => {
  const [reportList, setReportList] = useState([]);
  const [totalReports, setTotalReports] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [reportListArray, setReportListArray] = useState([]);
  const [filteredReports, setfilteredReports] = useState([]);
  // const [currentReportDetail, setCurrentReportDetail] = useState("");
  const [dialogContent, setDialogContent] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchDate, setSearchDate] = useState(dayjs());
  const [searchMode, setSearchMode] = useState('name'); // 'name' or 'date'

  const reportsPerPage = 10;  // Define the number of reports per page



  // const handleSearchByName = (event) => {

  //   setSearchName(event.target.value);
  //   const filteredReports = reportList.filter(report =>
  //     report.name.toLowerCase().includes(searchName.toLowerCase())
  //   );
  //   setfilteredReports(filteredReports);
  //   // setReportList(filteredReports);
  //   // if (searchName == '' ) {
  //   //   setReportList(reportListArray);
  //   // }
  // };

  // const handleSearchByDate = (date) => {
  //   let searchMonth = parseInt(date.month());
  //   let searchDay = parseInt(date.date());
  //   let searchYear = parseInt(date.year());

  //   if (searchYear < 100) {
  //     searchYear += 2000;
  //   }

  //   let esearching = new Date(searchYear, searchMonth, searchDay);
  //   console.log("Esearching : ", esearching);

  //   const filteredReports = reportList.filter(report => {
  //     const reportDate = dayjs(report.date);
  //     return reportDate.isSame(dayjs(date), 'day');
  //   });
  //   console.log(filteredReports);
  //   setfilteredReports(filteredReports);
  //   setSearchDate(date);
  // };


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSearchModeChange = (event, newMode) => {
    if (newMode !== null) {
      setSearchMode(newMode);
    }
  };

  const handleSearchByName = (event) => {
    setSearchName(event.target.value);
  };

  const handleSearchByDate = (date) => {
    setSearchDate(date);
  };

  const fetchReports = async () => {
    setLoading(true);
    const queryParam = searchMode === 'name' ? `name=${searchName}` : `date=${searchDate.format('YYYY-MM-DD')}`;
    try {
      const response = await fetch(`https://hr-backend-72v1.onrender.com/reportshow?${queryParam}&page=${currentPage}`);
      const data = await response.json();
      setReportList(data.reports);
      setTotalPages(Math.ceil(data.totalReports / 10));
    } catch (error) {
      console.error("Error fetching reports:");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [currentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };


  const handleDropdownChange = (event) => {
    setCurrentPage(event.target.value);
  };


  return (
    <div>
      <div className="search-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div class="night">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>

        <div className="search-fields" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <ToggleButtonGroup
            color="primary"
            value={searchMode}
            exclusive
            onChange={handleSearchModeChange}
            sx={{
              '& .MuiToggleButtonGroup-grouped': {
                margin: '8px', // Margin between buttons
                border: 1, // Removes default border
                borderColor: 'white',
                color: 'white',
                '&.Mui-selected': {
                  color: '#ffad3d',
                  borderColor: '#ffad3d',
                  backgroundColor: 'transparent',
                },
                '&:not(.Mui-selected)': {
                  color: 'white',
                  borderColor: 'white',
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 173, 61, 0.1)',
                  borderColor: '#ffad3d',
                  color: '#ffad3d',
                }
              }
            }}
          >
            <ToggleButton value="name">Search By Name</ToggleButton>
            <ToggleButton value="date">Search By Date</ToggleButton>
          </ToggleButtonGroup>
          {searchMode === 'name' ? (
            <TextField
              sx={{
                backgroundColor: "#4484FF",
                width: "90%",
                borderRadius: "10px",
                '& .MuiInputLabel-root': { color: '#ffad3d' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#ffad3d' },
                  '&:hover fieldset': { borderColor: '#ffad3d' },
                  '&.Mui-focused fieldset': { borderColor: '#ffad3d' },
                  '& input': { color: '#ffad3d' },
                },
              }}
              value={searchName}
              onChange={handleSearchByName}
              label="Search By Name"
              variant="outlined"
            />
          ) : (
            <TextField
              label="Search By Date"
              type="date"
              sx={{
                backgroundColor: "#4484FF",
                width: "90%",
                borderRadius: "10px",
                '& .MuiInputLabel-root': { color: '#ffad3d' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#ffad3d' },
                  '&:hover fieldset': { borderColor: '#ffad3d' },
                  '&.Mui-focused fieldset': { borderColor: '#ffad3d' },
                  '& input': { color: '#ffad3d' },
                },
              }}
              InputLabelProps={{ shrink: true }}
              value={searchDate.format('YYYY-MM-DD')}
              onChange={(e) => handleSearchByDate(dayjs(e.target.value))}
            />
          )}
          <Button onClick={fetchReports} variant="outlined" sx={{
            color: '#ffad3d',
            width: "150px",
            borderColor: '#ffad3d',
            '&:hover': {
              borderColor: '#ffad3d', // Keeps the border color on hover
              backgroundColor: 'rgba(255, 173, 61, 0.08)' // Light background on hover
            }
          }}>
            Find
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} className="leaveContainer">

        <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
          PROGRESS REPORTS
        </h4>
        <Table sx={{ minWidth: 650 }} className="leaveTable">
          <TableHead>
            <TableRow>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Name</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Email</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Position</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Date</span>
              </TableCell>
              <TableCell className="myTableHeadCells" align="right">
                <span className="myTableHeadSpan">Report</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReports.length > 0 ? (
              filteredReports.map((report) => (
                <TableRow key={report._id}>
                  <TableCell sx={{ fontSize: "large" }}>{report.name}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>{report.email}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>{"Junior Dev"}</TableCell>
                  <TableCell sx={{ fontSize: "large", height: "50px" }}>
                    {new Date(report.date).toLocaleString()}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: "large" }}>
                    <Button
                      onClick={() =>
                        handleOpenDialog({
                          name: report.name,
                          email: report.email,
                          date: report.date,
                          reportDetail: report.reportDetail,
                        })
                      }
                    >
                      View Report
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              reportList.map((report) => (
                <TableRow key={report._id}>
                  <TableCell sx={{ fontSize: "large" }}>{report.name}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>{report.email}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>{"Junior Dev"}</TableCell>
                  <TableCell sx={{ fontSize: "large", height: "50px" }}>
                    {new Date(report.date).toLocaleString()}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: "large" }}>
                    <Button
                      onClick={() =>
                        handleOpenDialog({
                          name: report.name,
                          email: report.email,
                          date: report.date,
                          reportDetail: report.reportDetail,
                        })
                      }
                    >
                      View Report
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", margin: "20px 0" }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />

          <Select
            value={currentPage}
            onChange={handleDropdownChange}
            sx={{ minWidth: 120 }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300, // Sets the maximum height of the dropdown to 300px
                },
              },
            }}
          >
            {Array.from({ length: totalPages }, (_, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                Page {index + 1}
              </MenuItem>
            ))}
          </Select>
        </div>
      </TableContainer>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogActions
          sx={{ position: "absolute", right: 0, padding: "15px 5px" }}
        >
          <Button onClick={handleCloseDialog}>
            <CloseIcon />
          </Button>
        </DialogActions>

        <div className="dialogTop">
          <div className="dialogLeft">
            <DialogTitle className="dialogHeading">Name</DialogTitle>
            <p className="dialogtext">{dialogContent.name}</p>
            <DialogTitle className="dialogHeading">Email</DialogTitle>
            <p className="dialogtext">{dialogContent.email}</p>
            <DialogTitle className="dialogHeading">Date</DialogTitle>
            <p className="dialogtext">
              {new Date(dialogContent.date).toLocaleString()}
            </p>
          </div>
          <div className="dialogRight">
            <img src={logo} width="220" height="220" alt="GROWTH GUILD" />
          </div>
        </div>
        <DialogTitle className="dialogHeading">Report</DialogTitle>
        <DialogContent className="dialogtextReport">
          {dialogContent.reportDetail}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProgressRep;
