import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "./navbar.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Avatar, Button, IconButton, Menu, MenuItem, Alert } from "@mui/material";
import { Howl } from "howler"; // Import howler for playing sound
import notificationSoundFile from '../../utilities/notification.mp3';


const MyNavbar = ({ user }) => {
  const [Employee, setEmployee] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [previousNotifications, setPreviousNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // For the notification menu
  const open = Boolean(anchorEl);
  const [newNotificationAlert, setNewNotificationAlert] = useState(false); // To show notification on top of page
  const [previousPendingCount, setPreviousPendingCount] = useState(0); // Track the previous count of pending notifications


  // Define the sound using howler
  const notificationSound = new Howl({
    src: [notificationSoundFile], // Add your sound file here
  });

  useEffect(() => {
    const loginEmployee = async () => {
      try {
        const response = await fetch(`https://hr-backend-72v1.onrender.com/loginemployee?email=${user.email}`);
        const data = await response.json();

        if (response.ok) {
          // console.log('Login successful:', data);
          setEmployee(data);
        } else {
          console.error('Login failed !');
        }
      } catch (error) {
        console.error('Error during login !');
      }
    };

    loginEmployee();
  }, [user.email]);

  // Fetch the last 5 notifications
  const fetchNotifications = async () => {
    try {
      const response = await fetch("https://hr-backend-72v1.onrender.com/recentNotifications"); // Adjust as necessary
      const data = await response.json();
      return data;
    } catch (error) {
      // console.error("Error fetching notifications:", error);
      return { notifications: [], pendingCount: 0 }; // Ensure the response format remains consistent
    }
  };
  
  useEffect(() => {
    if (user.role === 'HR' || user.role === 'Executive') {
      // Function to fetch and handle notifications
      const handleNotifications = async () => {
        const response = await fetchNotifications();
        const { notifications: fetchedNotifications, pendingCount } = response;
  
        // Log the data to ensure the fetch works
        // console.log("Fetched data:", fetchedNotifications, "Total pending:", pendingCount);
        // console.log("Fetched data:", fetchedNotifications, "Total pending:", pendingCount);
  
        // Compare the current pending count with the previous pending count
        if (pendingCount > previousPendingCount) {
          // console.log("New notifications detected!");
          // console.log("New notifications detected!");
  
          setNotifications(fetchedNotifications); // Update with new notifications
          setNewNotificationAlert(true); // Show new notification alert
  
          // Play notification sound
          notificationSound.play();
  
          // Update the previous pending count
          setPreviousPendingCount(pendingCount);
        } else {
          // console.log("No new notifications");
          // console.log("No new notifications");
          setNewNotificationAlert(false); // Optionally hide the notification alert
  
          // Update the previous pending count to match current
          setPreviousPendingCount(pendingCount);
        }
      };
  
      // Fetch notifications on component mount
      handleNotifications();
  
      // Poll every 30 seconds
      const intervalId = setInterval(handleNotifications, 30000);
  
      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [user.role, previousPendingCount]);
  
  
  
  
  
  

  const handleLogout = () => {
    // console.log(user);
    // console.log(user);
    localStorage.removeItem("userValue");
    window.location.reload();
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the notification menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the notification menu
  };

  return (
    <div className="header">
      <Navbar className="bg-body-tertiary navbar">
        <Avatar
          sx={{
            bgcolor: "black",
            marginRight: "10px",
            fontSize: 'x-large',
            width: "60px",
            height: "60px",
          }}
          src={Employee?.profilepic !== "https://via.placeholder.com/250" ? Employee?.profilepic : null}
        >
          {Employee?.profilepic === "https://via.placeholder.com/250" && user.name[0]}
        </Avatar>
        <div className="profile">
          <span>{user.name}</span>
          <span>{user.role}</span>
        </div>

        {/* Notification Button for HR or Executive */}
        {(user.role === 'HR' || user.role === 'Executive') && (
          <>
            <IconButton onClick={handleNotificationClick} sx={{marginRight: "20px"}}>
              <NotificationsNoneIcon fontSize="medium" sx={{ color: "white" }} />
            </IconButton>

            {/* Notification Menu */}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <p style={{textAlign: "center" , color: "gray"}}>Recent Notifications ... Go to Requests Tab to view all Notifications</p>
              {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                  <MenuItem key={index}>
                    <Alert sx={{width: "100%"}} severity="info">{`${notification.name} requested that their '${notification.field}' should be changed to '${notification.value}'`}</Alert>
                  </MenuItem>
                ))
              ) : (
                <MenuItem onClick={handleClose}>
                  <Alert severity="info">No new notifications</Alert>
                </MenuItem>
              )}
            </Menu>
          </>
        )}

        <button className="logoutBtn">
          <LogoutIcon onClick={handleLogout} />
        </button>
      </Navbar>

      {/* Top Page Notification Alert */}
      {newNotificationAlert && (
        <div className="top-notification">
          <Alert severity="info" onClose={() => setNewNotificationAlert(false)}>
            You have new notifications!
          </Alert>
        </div>
      )}
    </div>
  );
};

export default MyNavbar;
