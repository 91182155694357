import React, { useState, useEffect } from "react";
import "./Profiles.css";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
} from "@mui/material";
import PersonalDetails from '../../Employee/Personals/PersonalDetails';
import '../SearchContainer.css';


import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Row,
  Col,
  Breadcrumb,
  Card,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import { Input } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TableContainer,
  TextField,
  Typography,
  Box,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
const Profile = (props) => {
  const user = props.user;
  const [Employee, setEmployee] = useState(null);
  const [allEmployees, setAllEmployees] = useState(null);
  const [showProfileToggle, setshowProfileToggle] = useState(false);

  const [changedName, setchangedName] = useState("");
  const [changedEmail, setchangedEmail] = useState("");
  const [changedPhone, setchangedPhone] = useState("");
  const [changedAddress, setchangedAddress] = useState("");
  const [changedBankD, setchangedBankD] = useState("");
  const [changedJoiningDate, setchangedJoiningDate] = useState(new Date());
  const [changedDesignation, setchangedDesignation] = useState("");
  const [changedSallary, setchangedSallary] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [searchName, setSearchName] = useState('');


  const [filteredReports, setfilteredReports] = useState([]);


  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState(
    "abdulrehmanbadar191@gmail.com"
  );

  const [emails, setEmails] = useState([]);

  const handleSearchByName = (event) => {

    setSearchName(event.target.value);
    const filteredReports = emails.filter(report =>
      report.name.toLowerCase().includes(searchName.toLowerCase())
    );
    setfilteredReports(filteredReports);
  };




  const updateEmployeeField = async (email, field, value) => {
    try {
      const response = await fetch("https://hr-backend-72v1.onrender.com/updateEmployee", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          field,
          value,
        }),
      });
      const data = await response.json();
      if (data.success) {
        alert("Employee updated successfully");
        fetchEmployeeDetails(allEmployees.email);
      } else {
        alert("Error updating employee");
      }
    } catch (error) {
      alert("Error updating employee");
      // console.error(error);
    }
  };

  const inputEditHandler = (email, field, value, toggleEditField) => {
    updateEmployeeField(email, field, value);
    toggleEditField(false);
  };

  const handleChange = (event) => {
    setSelectedEmail(event.target.value);
    // console.log(selectedEmail);
    // console.log(selectedEmail);
  };

  // const handleCloseDialog = () => {
  //   setDialogOpen(false);
  // };

  const handleNameChange = (event) => {
    setchangedName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setchangedEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setchangedPhone(event.target.value);
  };
  const handleAddressChange = (event) => {
    setchangedAddress(event.target.value);
  };
  const handleBankDChange = (event) => {
    setchangedBankD(event.target.value);
  };
  const handleJoiningChange = (event) => {
    setchangedJoiningDate(new Date(event.target.value));
    // console.log(typeof changedJoiningDate);
    // console.log(changedJoiningDate);
    // console.log(typeof changedJoiningDate);
    // console.log(changedJoiningDate);
  };
  const handleDesignationChange = (event) => {
    setchangedDesignation(event.target.value);
  };
  const handleSalaryChange = (event) => {
    setchangedSallary(event.target.value);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const loginEmployee = async () => {
      try {
        const response = await fetch(
          `https://hr-backend-72v1.onrender.com/loginemployee?email=${selectedEmail}`
        );
        const data = await response.json();

        if (response.ok) {
          // console.log("Login successful:", data);
          setEmployee(data);
        } else {
          console.error("Login failed!");
        }
      } catch (error) {
        console.error("Error during login!");
      }
    };
    loginEmployee();
  }, [selectedEmail]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/employees/emails");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEmails(data);
      } catch (error) {
        console.error("Error fetching emails:");
      }
    };

    fetchEmails();
  }, []);

  const fetchEmployeeDetails = async (email) => {
    try {
      const response = await fetch(`https://hr-backend-72v1.onrender.com/allemployees/${email}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // console.log("data in here  ", data);
      // console.log("data in here  ", data);
      setAllEmployees(data);
      setshowProfileToggle(true);
      // setcurrentEmployeeChangeEmail(data.email);
    } catch (error) {
      console.error("Error fetching employee details:");
    }
  };
  const handleDontShowProfile = () => {
    setshowProfileToggle(false);
  }
  /////////////////////////////////////////////////////////////////////////////////////////// https://hr-backend-72v1.onrender.com
  return (
    <>

      {!showProfileToggle &&
        <h4
          className="myTableHeader animate__animated animate__lightSpeedInLeft"
          style={{ margin: "20px", marginLeft: "5%" }}
        >
          Our Employees
        </h4>
      }

      {!showProfileToggle &&
        <div className="search-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px", marginBottom: "30px" }}>
          <div class="night">
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
          </div>
          <div className="search-fields" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <TextField
              sx={{
                backgroundColor: "#4484FF",
                borderRadius: "10px",
                borderRadius: "10px",
                '& .MuiInputLabel-root': { color: '#ffad3d' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#ffad3d' },
                  '&:hover fieldset': { borderColor: '#ffad3d' },
                  '&.Mui-focused fieldset': { borderColor: '#ffad3d' },
                  '& input': { color: '#ffad3d' },
                },
              }}
              onChange={handleSearchByName}
              className="search-input"
              label="Search By Name"
              variant="outlined"
            />
          </div>
        </div>
      }

      {!showProfileToggle &&
        <div className="cardContainerP">
          {(filteredReports.length > 0 ? filteredReports : emails).map((employee) => {
            return (
              <Card className={`cardProfile animate__animated animate__fadeInUp`}>
                <Card.Header className="cardProfileHeader">
                  <Avatar className="avatarr">{employee.name[0]}</Avatar>
                </Card.Header>
                <Card.Body className="cardProfileBody">
                  <h4>{employee.name}</h4>
                  <p>{employee.pos}</p>
                  <Button onClick={() => fetchEmployeeDetails(employee.email)}>
                    View Details
                  </Button>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      }

      {allEmployees && showProfileToggle && (
        <PersonalDetails
          user={user}
          Employee={allEmployees}
          handleDontShowProfile={handleDontShowProfile}
          inputEditHandler={inputEditHandler}
        />
      )}
    </>
  );
};

export default Profile;
