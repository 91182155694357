// src/Sidebar.js
import React from "react";
import logo from "../../../utilities/logo-png.png";
import "./sidebarr.css";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
// import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
// import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";

const Sidebar = (props) => {
  const dashClass = props.dashbaordToggle ? "activeNav" : "deactivateNav";
  const addEmpClass = props.addEmployeeToggle ? "activeNav" : "deactivateNav";
  const progRepClass = props.progressReportsToggle
    ? "activeNav"
    : "deactivateNav";
  const reqdLvsClass = props.requestedLeavesToggle
    ? "activeNav"
    : "deactivateNav";
  const pastTimClass = props.pastTimersToggle ? "activeNav" : "deactivateNav";
  const projClass = props.projectsToggle ? "activeNav" : "deactivateNav";
  const empDetailClass = props.employeeDetailsToggle
    ? "activeNav"
    : "deactivateNav";
  const eventClass = props.eventsToggle ? "activeNav" : "deactivateNav";
  // const countClass = props.myCountdownToggle ? "activeNav" : "deactivateNav";
  // const reqALeaveClass = props.requestALeaveToggle
  //   ? "activeNav"
  //   : "deactivateNav";

  return (
    <div className="sidebar-space">
      <div className={`sidebar animate__animated animate__fadeInLeft`}>
        <div className="side-content">
          <div className="logo">
            <img
              src={logo}
              width="120"
              height="120"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            <span>Growth Guild</span>
          </div>
          <div className="buttons">
            {/* EMPTY */}
            <button
              className={`navButton ${dashClass}`}
              onClick={props.onDashboard}
            >
              <DashboardOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Dashboard
            </button>
            <button
              className={`navButton ${addEmpClass}`}
              onClick={props.onAddEmployee}
            >
              <PersonAddOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Add Employee
            </button>
            <button
              className={`navButton ${progRepClass}`}
              onClick={props.onShowProgress}
            >
              <EqualizerOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Progress Reports
            </button>
            <button
              className={`navButton ${reqdLvsClass}`}
              onClick={props.onShowLeaves}
            >
              <CollectionsBookmarkOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Requests
            </button>
            <button
              className={`navButton ${pastTimClass}`}
              onClick={props.onShowTimers}
            >
              <HistoryOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Past Timers
            </button>
            <button
              className={`navButton ${projClass}`}
              onClick={props.onShowProjectA}
            >
              <CategoryOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Projects
            </button>
            <button
              className={`navButton ${empDetailClass}`}
              onClick={props.onShowProfile}
            >
              <SwitchAccountOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Employee Details
            </button>
            {/* EMPTY */}
            <button
              className={`navButton ${eventClass}`}
              onClick={props.onShowEvents}
            >
              <CelebrationOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Upcoming Events
            </button>
            {/* EMPTY */}
            {/* <button
              className={`navButton ${countClass}`}
              onClick={props.onMyCountdown}
            >
              <SpeedOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Countdown
            </button> */}
            {/* EMPTY */}
            {/* <button
              className={`navButton ${reqALeaveClass}`}
              onClick={props.onRequestALeave}
            >
              <AddReactionOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Request A Leave
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
