import React, { useState, useEffect, useRef } from "react";
import '../Employee/employee.css'
// import "./employee.css";
import "../button.css";
import RequestLeave from '../Employee/RequestLeave/RequestLeave';
import NotificationComponent from "../../NotificationCard";

import StartIcon from '../../utilities/start.svg';
import PauseIcon from '../../utilities/pause.svg';
import ResumeIcon from '../../utilities/resume.svg';
import StopIcon from '../../utilities/stop.svg';

import { Alert, Snackbar } from "@mui/material";
import AddEmployee from "./AddEmployee/addEmployee";
import Requests from "./Requests/requestedLeaves";
import PastTimers from "./pastTimers/pastTimers";
import ProgressReports from "./Show Progress Reports/progressReports";
import Profile from "./Profiles/profiles";
import Navbar from "../Navbar/navbar";
import RunningTimers from "./currentTimers/timers";
// import RequestLeave from "./Request A Leave/RequestLeave";
import Countdown from "./My Countdown/Countdown";

import "./hr.css";
import Sidebar from "./Sidebar/sidebar";
import Dashboard from "./Dashboard/Dashboard";
import Events from "./Events/Events";
import Mastermind from "./Projects/Mastermind";
import Projects from "./Projects/Projects";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TableContainer,
  TextField,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Dialog,
} from '@mui/material';

const Hr = (props) => {
  const user = props.user;
  const [Employee, setEmployee] = useState(null);
  const [lastInactive, setLastInactive] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const timeoutRef = useRef(null);
  const [showMainReport, setShowMainReport] = useState(false);
  const [selectedProject, setselectedProject] = useState('');
  const [appointmentTaken, setAppointmentTaken] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [reportDetail, setreportDetail] = useState('');
  const [pipelineMoved, setPipelineMoved] = useState('');
  const [emailSent, setEmailSent] = useState('');
  let [timerCount, setTimerCount] = useState(0);







  const [dashbaordToggle, setDashbaordToggle] = useState(true);
  const [addEmployeeToggle, setAddEmployeeToggle] = useState(false);
  const [progressReportsToggle, setProgressReportsToggle] = useState(false);
  const [requestedLeavesToggle, setRequestedLeavesToggle] = useState(false);
  const [pastTimersToggle, setPastTimersToggle] = useState(false);
  const [projectsToggle, setProjectsToggle] = useState(false);
  const [employeeDetailsToggle, setEmployeeDetailsToggle] = useState(false);

  const [eventsToggle, setEventsToggle] = useState(false);
  const [myCountdownToggle, setMyCountdownToggle] = useState(false);
  const [requestALeaveToggle, setRequestALeaveToggle] = useState(false);

  useEffect(() => {//
    const loginEmployee = async () => {
      try {
        const response = await fetch(`https://hr-backend-72v1.onrender.com/loginemployee?email=${user.email}`);
        const data = await response.json();

        if (response.ok) {
          // console.log('Login successful:', data);
          setEmployee(data);
        } else {
          console.error('Login failed:');
        }
      } catch (error) {
        console.error('Error during login:');
      }
    };

    loginEmployee();
  }, [user.email]);

  useEffect(() => {
    if (myCountdownToggle) {
      setTimerCount(1);
    }
  }, [myCountdownToggle])

  const dashbaordToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(true);

    setEventsToggle(false);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(false);
  };
  const handleOpenAddEmployee = () => {
    setAddEmployeeToggle(true);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);

    setEventsToggle(false);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(false);
  }
  const handleCloseAddEmployee = () => setAddEmployeeToggle(false);

  const progressReportsToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(true);
    setDashbaordToggle(false);
    setEventsToggle(false);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(false);
  };
  const requestedLeavesToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(true);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(false);
  };
  const pastTimersToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(true);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(false);
  };
  const projectsToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(true);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(false);
  };
  const employeeDetailsToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(true);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(false);
  };
  const eventsToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(true);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(false);
  };
  const myCountdownToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    setMyCountdownToggle(true);
    setRequestALeaveToggle(false);
  };
  const requestALeaveToggleHandler = () => {
    setAddEmployeeToggle(false);
    setRequestedLeavesToggle(false);
    setPastTimersToggle(false);
    setProjectsToggle(false);
    setEmployeeDetailsToggle(false);
    setProgressReportsToggle(false);
    setDashbaordToggle(false);
    setEventsToggle(false);
    setMyCountdownToggle(false);
    setRequestALeaveToggle(true);
  };

  const handleStopClick = () => {
    setOpenDialog(true);
  };
  const handleProjectDropChange = (event) => {
    setselectedProject(event.target.value);
  }
  const handleAppointmentTakenChange = (event) => {
    setAppointmentTaken(event.target.value);
  };

  const handleEmailSentChange = (event) => {
    setEmailSent(event.target.value);
  };
  const handlePipelineMovedChange = (event) => {
    setPipelineMoved(event.target.value);
  };
  const handleReportDetail = (event) => {
    setreportDetail(event.target.value);
  };

  const handleReportSubmit = async () => {
    try {
      let reportData;

      if (selectedProject === 'MasterMind') {
        reportData = {
          name: user.name,
          email: user.email,
          appointmentsTaken: appointmentTaken,
          emailSent,
          pipelineMoved,
          date: new Date(),
        };

        const response = await fetch('https://hr-backend-72v1.onrender.com/projecta', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reportData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.text();
        // console.log('MasterMind Response:', responseData);
        // console.log('MasterMind Response:', responseData);

      } else if (selectedProject === 'Progress Report') {
        reportData = {
          name: user.name,
          email: user.email,
          reportDetail,
        };

        const response = await fetch('https://hr-backend-72v1.onrender.com/reports', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reportData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.text();
        // console.log('Progress Report Response:', responseData);
        // console.log('Progress Report Response:', responseData);
      }
      alert("Report Submitted Successfully");
      window.location.reload();

    } catch (error) {
      alert("There Was an Error Submitting Your Request");
      // console.error('Error submitting report:', error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmSubmit = () => {
    handleStop();
    setShowMainReport(true);
    setOpenDialog(false);
  };

  useEffect(() => {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      // Listener for messages from the service worker (optional for handling updates)
      const swListener = (event) => {
        if (event.data.type === 'timerUpdate' && event.data.email === user.email) {
          setTime(event.data.time); // Update time based on the service worker's data
        }
      };

      // Add the listener for service worker messages
      navigator.serviceWorker.addEventListener('message', swListener);

      return () => {
        navigator.serviceWorker.removeEventListener('message', swListener);
      };
    }
  }, [user.email]);

  useEffect(() => {
    const fetchTimerRecord = async () => {
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/get-timer-record", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ email: user.email }) // Send email in the request body
        });
        const data = await response.json();
        if (response.ok) {
          // Set the time and updatedAt values
          setTime(data.record.time); // Assuming your timer record has a 'time' field
          setLastInactive(data.record.updatedAt); // Set updatedAt value
        } else {
          setTime(0);
        }
      } catch (error) {
        setTime(0);
      }
    };
    if (myCountdownToggle && timerCount < 1) {
      fetchTimerRecord();
    }
  }, [user.email , myCountdownToggle]);
  useEffect(() => {
    if (lastInactive) {
      const formattedTime = formatTime(time);
      setAlertMessage(`You were inactive since ${new Date(lastInactive).toLocaleString()}. Your timer is going to start from ${formattedTime} seconds.`);
      setShowAlert(true);
    }
  }, [lastInactive]);
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  // useEffect(() => {
  //   if (isRunning && !isPaused) {
  //     console.log(`Timer is running: ${hours}h ${minutes}m ${seconds}s`);
  //   }
  // }, [time, isRunning, isPaused, hours, minutes, seconds]);

  // Send timer actions to the service worker
  useEffect(() => {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      if (isRunning && !isPaused) {
        // Start the timer
        navigator.serviceWorker.controller.postMessage({
          action: 'startTimer',
          name: user.name,
          email: user.email,
          time,
          isRunning: true,
          isPaused: false,
        });
      } else if (isPaused) {
        // Pause the timer
        navigator.serviceWorker.controller.postMessage({
          action: 'pauseTimer',
          name: user.name,
          email: user.email,
          isRunning: isRunning,
          isPaused: true,
        });

        // Alert after 1 minute of being paused
        timeoutRef.current = setTimeout(() => {
          if (isPaused && !isStopped) {
            navigator.serviceWorker.controller.postMessage({
              action: 'alert',
              email: user.email,
              message: 'User has not resumed the timer after 1 minute',
            });
          }
        }, 60000);
      } else if (!isRunning && !isStopped) {
        // Resume the timer
        navigator.serviceWorker.controller.postMessage({
          action: 'resumeTimer',
          name: user.name,
          email: user.email,
          isRunning: true,
          isPaused: false,
        });
      } else if (isStopped) {
        // Stop the timer
        navigator.serviceWorker.controller.postMessage({
          action: 'stopTimer',
          name: user.name,
          email: user.email,
          isRunning: false,
          isPaused: true,
        });
      }
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isRunning, isPaused, isStopped, time, user.email]);

  // const sendToMongoDB = async (name, email, time) => {
  //   console.log("Sending data to MongoDB:", { name, email, time, updatedAt: new Date() });
  //   try {
  //     const response = await fetch("https://hr-backend-72v1.onrender.com/timer-records", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         name,
  //         email,
  //         time,
  //         updatedAt: new Date(),
  //       }),
  //     });
  //     const data = await response.json();
  //     if (response.ok) {
  //       console.log("Successfully sent timer record to MongoDB:", data);
  //     } else {
  //       console.error("Failed to send timer record:", data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error sending timer record to MongoDB:", error);
  //   }
  // };

  // Start the timer
  const handleStart = () => {
    setIsRunning(true);
    setIsPaused(false);
    setIsStopped(false);
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'startTimer',
        name: user.name,
        email: user.email,
        time,
        isRunning: true,
        isPaused: false,
      });
    }
  };

  // Pause the timer
  const handlePause = () => {
    setIsPaused(true);
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'pauseTimer',
        name: user.name,
        email: user.email,
        isRunning: isRunning,
        isPaused: true,
      });
    }
  };

  // Resume the timer
  const handleResume = () => {
    setIsPaused(false);
    setIsStopped(false);
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'resumeTimer',
        name: user.name,
        email: user.email,
        isRunning: true,
        isPaused: false,
      });
    }
    clearTimeout(timeoutRef.current);
  };

  // Stop the timer
  const handleStop = async () => {
    setIsRunning(false);
    setIsPaused(false);
    setIsStopped(true);

    const formattedTime = formatTime(time);
    const timerData = {
      email: user.email,
      name: user.name,
      date: new Date().toISOString(),
      time: formattedTime,
    };

    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/storeTimerData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(timerData),
      });

      if (response.ok) {
        console.log('Timer data stored successfully');
      } else {
        console.error('Failed to store timer data');
      }
    } catch (error) {
      console.error('Error storing timer data:');
    }

    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'stopTimer',
        name: user.name,
        email: user.email,
        isRunning: false,
        isPaused: false,
      });
    }
    const userMail = user.email;
    deleteTimerRecord(userMail);
  };

  async function deleteTimerRecord(email) {
    try {
      const response = await fetch(`https://hr-backend-72v1.onrender.com/timer-records?email=${email}`, {
        method: "DELETE",
      });
      const data = await response.json();
      if (response.ok) {
        console.log("Timer record deleted successfully:", data);
      } else {
        console.error("Failed to delete timer record:", data.message);
      }
    } catch (error) {
      console.error("Error deleting timer record:", error);
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);

  const beforeUnloadHandler = (event) => {
    if ('serviceWorker' in navigator && navigator.serviceWorker.ready) {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        if (!isStopped) {
          navigator.serviceWorker.controller.postMessage({
            action: 'pauseTimer',
            name: user.name,
            email: user.email,
            isRunning: isRunning,
            isPaused: true,
          });
        }
      }
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then((boolean) => {
          console.log(`Service worker unregistered: ${boolean}`);
        });
      });
    }
  };

  const formatTime = (time) => {
    if (typeof time !== 'number' || isNaN(time)) {
      return '00:00:00';
    }
    return new Date(time * 1000).toISOString().substr(11, 8);
  };

  return (
    <div className="hr">
      <Sidebar
        onDashboard={dashbaordToggleHandler}
        onAddEmployee={handleOpenAddEmployee}
        onShowLeaves={requestedLeavesToggleHandler}
        onShowTimers={pastTimersToggleHandler}
        onShowProgress={progressReportsToggleHandler}
        onShowProjectA={projectsToggleHandler}
        onShowProfile={employeeDetailsToggleHandler}
        onShowEvents={eventsToggleHandler}
        onMyCountdown={myCountdownToggleHandler}
        onRequestALeave={requestALeaveToggleHandler}
        dashbaordToggle={dashbaordToggle}
        addEmployeeToggle={addEmployeeToggle}
        progressReportsToggle={progressReportsToggle}
        requestedLeavesToggle={requestedLeavesToggle}
        pastTimersToggle={pastTimersToggle}
        projectsToggle={projectsToggle}
        employeeDetailsToggle={employeeDetailsToggle}
        eventsToggle={eventsToggle}
        myCountdownToggle={myCountdownToggle}
        requestALeaveToggle={requestALeaveToggle}
      />

      <div>
        <NotificationComponent
          alertMessage={alertMessage}
          showAlert={showAlert}
          handleCloseAlert={handleCloseAlert}
        />

      </div>

      <div className="content">
        <Navbar user={user} />
        {dashbaordToggle && <Dashboard user={user} />}
        {addEmployeeToggle && (
          <AddEmployee
            addEmployeeToggle={addEmployeeToggle}

          />
        )}
        {progressReportsToggle && (
          <ProgressReports progressReportsToggle={progressReportsToggle} />
        )}
        {requestedLeavesToggle && (
          <Requests requestedLeavesToggle={requestedLeavesToggle} user={user} />
        )}
        {pastTimersToggle && <PastTimers pastTimersToggle={pastTimersToggle} />}
        {projectsToggle && <Projects projectsToggle={projectsToggle} />}
        {employeeDetailsToggle && (
          <Profile user={user} employeeDetailsToggle={employeeDetailsToggle} />
        )}
        {eventsToggle && <Events user={user} />}
        {myCountdownToggle &&
          <div className="timer">
            <p>T H E<span className="countdown-space"></span>C O U N T D O W N</p>

            <div className="countdown-timer">
              <div className="hours">
                <div className="timer-blocks">
                  <p>{Math.floor(time / 3600)}</p>
                </div>
                <p>HRS</p>
              </div>
              <div className="colons">
                <div className="rectangles">
                </div>
                <div className="rectangles">
                </div>
              </div>
              <div className="minutes">
                <div className="timer-blocks">
                  <p>{Math.floor((time % 3600) / 60)}</p>
                </div>
                <p>MIN</p>
              </div>
              <div className="colons">
                <div className="rectangles">
                </div>
                <div className="rectangles">
                </div>
              </div>
              <div className="seconds">
                <div className="timer-blocks">
                  <p>{time % 60}</p>
                </div>
                <p>SEC</p>
              </div>
            </div>
            {!showMainReport && (
              <div className="toggles">
                <button onClick={handleStart} className="start toggle-btns">
                  Start
                  <img className="toggleImgs" src={StartIcon} alt="Start Icon" width="24" height="24" />
                </button>
                <button onClick={handlePause} className="pause toggle-btns">
                  Pause
                  <img className="toggleImgs" src={PauseIcon} alt="Pause Icon" width="24" height="24" />
                </button>
                <button onClick={handleResume} className="resume toggle-btns">
                  Resume
                  <img className="toggleImgs" src={ResumeIcon} alt="Resume Icon" width="24" height="24" />
                </button>
                <button className="stop toggle-btns" onClick={handleStopClick}>
                  Stop
                  <img className="toggleImgs" src={StopIcon} alt="Stop Icon" width="24" height="24" />
                </button>
              </div>
            )}
            {showMainReport && (
              <div className="main-report">
                <div className="submit-report-head">
                  <p>Submit Report</p>
                </div>

                <div className="project-type">
                  <FormControl fullWidth>
                    <InputLabel id="progress-report-submission">Mastermind / Progress Report</InputLabel>
                    <Select
                      labelId="progress-report-submission"
                      id="progress"
                      value={selectedProject}
                      label="Mastermind / Progress Report"
                      onChange={handleProjectDropChange}
                    >
                      <MenuItem value="MasterMind">MasterMind</MenuItem>
                      <MenuItem value="Progress Report">Progress Report</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {selectedProject === 'MasterMind' && (
                  <div className="mastermind-submit">
                    <div className="appointments">
                      <TextField
                        margin="dense"
                        id="appointmentTaken"
                        label="Appointment Taken"
                        type="number"
                        fullWidth
                        value={appointmentTaken}
                        onChange={handleAppointmentTakenChange}
                      />
                    </div>
                    <div className="emailsent">
                      <FormControl fullWidth>
                        <InputLabel id="email-sent">Email Sent</InputLabel>
                        <Select
                          labelId="email-sent"
                          id="email-sent"
                          value={emailSent}
                          label="Email Sent"
                          onChange={handleEmailSentChange}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="pipelines">
                      <FormControl fullWidth>
                        <InputLabel id="pipeline-moved">Pipeline Moved</InputLabel>
                        <Select
                          labelId="pipeline-moved"
                          id="pipeline-moved"
                          value={pipelineMoved}
                          label="Pipeline Moved"
                          onChange={handlePipelineMovedChange}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}

                {selectedProject === 'Progress Report' && (
                  <div className="progress-report-submit">
                    <TextField
                      margin="dense"
                      id="reportdetail"
                      label="Report Detail"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      value={reportDetail}
                      onChange={handleReportDetail}
                      sx={{ flex: 1 }}
                    />
                  </div>
                )}
                <div className="report-submit">
                  <button className="submit-btn" onClick={handleReportSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}


          </div>
        }
        {requestALeaveToggle &&
          <RequestLeave
            user={user}
            Employee={Employee}
          />}


        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you ready to Submit Progress Report?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm if you're ready to submit the progress report.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              No
            </Button>
            <Button onClick={handleConfirmSubmit} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    </div>
  );
};

export default Hr;
