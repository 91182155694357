import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    // DialogActions,
    // DialogContent,
    // TableContainer,
    TextField,
    // Box,
    // InputLabel,
    // MenuItem,
    // FormControl,
    // Select,
    // Table,
    // TableBody,
    // TableCell,
    // TableHead,
    // TableRow,
    // Paper,
    // Dialog,
} from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./personalInfo.css";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoneIcon from "@mui/icons-material/Done";

const PersonalInfo = (props) => {


    const [toggleEditName, settoggleEditName] = useState(false);
    const [toggleEditPhone, settoggleEditPhone] = useState(false);
    const [toggleEditAddress, settoggleEditAddress] = useState(false);
    const [toggleEditPosition, settoggleEditPosition] = useState(false);
    const [toggleEditJoining, settoggleEditJoining] = useState(false);
    const [toggleEditBirthday, settoggleEditBirthday] = useState(false);
    const [toggleEditEmail, settoggleEditEmail] = useState(false);
    const [toggleEditPassword, settoggleEditPassword] = useState(false);
    const [toggleEditEmergency, settoggleEditEmergency] = useState(false);

    const Employee = props.Employee;
    const user = props.user;




    const [name, setName] = useState(Employee ? Employee.name : "");
    const [email, setEmail] = useState(Employee ? Employee.email : "");
    const [phone, setPhone] = useState(Employee ? Employee.phone : "");
    const [address, setAddress] = useState(Employee ? Employee.address : "");
    const [bankD, setBankD] = useState(Employee ? Employee.bankD : "");
    const [joining, setJoining] = useState(Employee ? Employee.joining.slice(0, 10) : "");
    const [designation, setDesignation] = useState(Employee ? Employee.designation : "");
    const [password, setPassword] = useState(Employee ? Employee.pass : "");
    const [position, setPosition] = useState(Employee ? Employee.pos : "");
    const [emergency, setEmergency] = useState(Employee ? Employee.emergency : "");
    const [birthday, setBirthday] = useState(Employee ? Employee.birthday.slice(0, 10) : "");

    const inputEditHandler = (name, email, field, value, toggleEditField) => {
        trackEmployeeUpdateRequest(name, email, field, value);
        toggleEditField(false);
    };

    const trackEmployeeUpdateRequest = async (name, email, field, value) => {
        try {
            const response = await fetch("https://hr-backend-72v1.onrender.com/requestEmployeeUpdate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    email,
                    field,
                    value,
                }),
            });
            const data = await response.json();
            if (data.success) {
                alert("Update request submitted successfully");
            } else {
                alert("Error submitting update request");
            }
        } catch (error) {
            alert("Error submitting update request");
            // console.error(error);
        }
    };



    return (
        <div>
            <div className="personal-container">
                <div className="personal-head">
                    <h2 className="personal-head-heading">Personal</h2>
                </div>
                <hr />
                <div className="basic-info-head">
                    <p>Basic Information</p>
                </div>
                <div className="personal-info-row-1">
                    <div className="Emp-label-input">
                        <p>Full Name</p>
                        <div className="emp-input">
                            {!toggleEditName ? (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    value={name}
                                    readOnly
                                />
                            ) : (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    placeholder="Enter Name here"
                                    maxLength="50"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            )}
                            {user.role === 'Employee' && (
                                !toggleEditName ? (
                                    <Button onClick={() => settoggleEditName(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            inputEditHandler(Employee.name, Employee.email, "name", name, settoggleEditName)
                                        }
                                    >
                                        <DoneIcon />
                                    </Button>
                                )
                            )}
                        </div>
                    </div>

                    <div className="Emp-label-input">
                        <p>Phone</p>
                        <div className="emp-input">
                            {!toggleEditPhone ? (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    value={phone}
                                    readOnly
                                />
                            ) : (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    placeholder="Enter Phone here"
                                    maxLength="50"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            )}
                            {user.role === 'Employee' && (
                                !toggleEditPhone ? (
                                    <Button onClick={() => settoggleEditPhone(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            inputEditHandler(Employee.name, Employee.email, "phone", phone, settoggleEditPhone)
                                        }
                                    >
                                        <DoneIcon />
                                    </Button>
                                )
                            )}

                        </div>
                    </div>

                    <div className="Emp-label-input">
                        <p>Address</p>
                        <div className="emp-input">
                            {!toggleEditAddress ? (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    value={address}
                                    readOnly
                                />
                            ) : (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    placeholder="Enter Address here"
                                    maxLength="50"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            )}
                            {user.role === 'Employee' && (
                                !toggleEditAddress ? (
                                    <Button onClick={() => settoggleEditAddress(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            inputEditHandler(Employee.name, Employee.email, "address", address, settoggleEditAddress)
                                        }
                                    >
                                        <DoneIcon />
                                    </Button>
                                )
                            )}

                        </div>
                    </div>
                </div>

                <div className="personal-info-row-2">
                    <div className="Emp-label-input">
                        <p>Position</p>
                        <div className="emp-input">
                            {!toggleEditPosition ? (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    value={position}
                                    readOnly
                                />
                            ) : (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    placeholder="Enter Position here"
                                    maxLength="50"
                                    value={position}
                                    onChange={(e) => setPosition(e.target.value)}
                                />
                            )}
                            {user.role === 'Employee' && (
                                !toggleEditPosition ? (
                                    <Button onClick={() => settoggleEditPosition(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            inputEditHandler(Employee.name, Employee.email, "pos", position, settoggleEditPosition)
                                        }
                                    >
                                        <DoneIcon />
                                    </Button>
                                )
                            )}

                        </div>
                    </div>

                    <div className="Emp-label-input">
                        <p>Joining Date</p>
                        <div className="emp-input">
                            {!toggleEditJoining ? (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    value={joining} // Display only the date part
                                    readOnly
                                />
                            ) : (
                                <DatePicker
                                    selected={joining ? new Date(joining) : new Date()}
                                    onChange={(date) => setJoining(date.toISOString().slice(0, 10))}
                                    className="input-like-info"
                                    dateFormat="yyyy-MM-dd" // Format date as Year-Month-Day
                                />
                            )}
                            {user.role === 'Employee' && (
                                !toggleEditJoining ? (
                                    <Button onClick={() => settoggleEditJoining(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            inputEditHandler(Employee.name, Employee.email, "joining", joining, settoggleEditJoining)
                                        }
                                    >
                                        <DoneIcon />
                                    </Button>
                                )
                            )}
                        </div>
                    </div>

                    <div className="Emp-label-input">
                        <p>Birthday</p>
                        <div className="emp-input">
                            {!toggleEditBirthday ? (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    value={birthday} // Display only the date part
                                    readOnly
                                />
                            ) : (
                                <DatePicker
                                    className="input-like-info"
                                    selected={new Date(birthday)}
                                    onChange={(date) => setBirthday(date.toISOString().slice(0, 10))}
                                    dateFormat="yyyy-MM-dd" // Format date as Year-Month-Day
                                />
                            )}
                            {user.role === 'Employee' && (
                                !toggleEditBirthday ? (
                                    <Button onClick={() => settoggleEditBirthday(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            inputEditHandler(Employee.name, Employee.email, "birthday", birthday, settoggleEditBirthday)
                                        }
                                    >
                                        <DoneIcon />
                                    </Button>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className="personal-info-row-3">
                    <div className="Emp-label-input">
                        <p>Email</p>
                        <div className="emp-input">
                            {!toggleEditEmail ? (
                                <input className="input-like-info" type="text" value={email} readOnly />
                            ) : (
                                <input
                                    className="input-like-info"
                                    type="email"
                                    value={email}
                                    placeholder="Enter Email here"
                                    maxLength="50"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            )}
                            {/* {user.role === 'Employee' && (
                                !toggleEditEmail ? (
                                    <Button onClick={() => settoggleEditEmail(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button onClick={() => inputEditHandler(Employee.name, email, "email", email, settoggleEditEmail)}>
                                        <DoneIcon />
                                    </Button>
                                )
                            ) } */}

                        </div>
                    </div>

                    <div className="Emp-label-input">
                        <p>Password</p>
                        <div className="emp-input">
                            {!toggleEditPassword ? (
                                <input className="input-like-info" type="text" value={password} readOnly />
                            ) : (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    value={password}
                                    placeholder="Enter Password here"
                                    maxLength="50"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            )}
                            {/* {user.role === 'Employee' && (
                                !toggleEditPassword ? (
                                    <Button onClick={() => settoggleEditPassword(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button onClick={() => inputEditHandler(Employee.name, email, "pass", password, settoggleEditPassword)}>
                                        <DoneIcon />
                                    </Button>
                                )
                            ) } */}

                        </div>
                    </div>

                    <div className="Emp-label-input">
                        <p>Emergency Contact</p>
                        <div className="emp-input">
                            {!toggleEditEmergency ? (
                                <input className="input-like-info" type="text" value={emergency} readOnly />
                            ) : (
                                <input
                                    className="input-like-info"
                                    type="text"
                                    value={emergency}
                                    placeholder="Enter Emergency Contact here"
                                    maxLength="50"
                                    onChange={(e) => setEmergency(e.target.value)}
                                />
                            )}
                            {user.role === 'Employee' && (
                                !toggleEditEmergency ? (
                                    <Button onClick={() => settoggleEditEmergency(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button onClick={() => inputEditHandler(Employee.name, email, "emergency", emergency, settoggleEditEmergency)}>
                                        <DoneIcon />
                                    </Button>
                                )
                            )}

                        </div>
                    </div>
                </div>
                {/* <div className="personal-info-row-3">

                </div> */}
            </div>
        </div>
    );
};

export default PersonalInfo;