import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material';
import "./documents.css";
import DualBall from '../../../../utilities/dual ball back.svg';

const Documents = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [loader, showLoader] = useState(false);

    const Employee = props.Employee;
    const user = props.user;

    const fetchDocuments = async () => {
        try {
            const response = await fetch(`https://hr-backend-72v1.onrender.com/documents?email=${Employee.email}`);
            const data = await response.json();

            if (Array.isArray(data)) {
                const filteredDocuments = data.filter(doc => doc.status === 'Pending' || doc.status === 'Accepted');
                const sortedDocuments = filteredDocuments.sort((a, b) => {
                    if (a.status === 'Pending' && b.status === 'Accepted') return -1;
                    if (a.status === 'Accepted' && b.status === 'Pending') return 1;
                    return 0;
                });

                setDocuments(sortedDocuments);
            } 
            else {
                console.error('Unexpected response format:', data);
            }
        } catch (error) {
            console.error('Error fetching documents:');
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [Employee]);

    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };

    const onFileUpload = async () => {
        showLoader(true);  
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('name', Employee.name);
        formData.append('email', Employee.email);

        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/upload', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            // console.log('File uploaded successfully:', data);
            // console.log('File uploaded successfully:', data);
            showLoader(false);  

            fetchDocuments();  
        } catch (error) {
            console.error('Error:');
            showLoader(false);  
        }
    };

    const handleApprove = async (documentId) => {
        try {
            const response = await fetch(`https://hr-backend-72v1.onrender.com/documents/${documentId}/approve`, {
                method: 'POST',
            });
            if (response.ok) {
                fetchDocuments();
            }
        } catch (error) {
            console.error('Error approving document:');
        }
    };

    const handleReject = async (documentId) => {
        try {
            const response = await fetch(`https://hr-backend-72v1.onrender.com/documents/${documentId}/reject`, {
                method: 'POST',
            });
            if (response.ok) {
                fetchDocuments();
            }
        } catch (error) {
            console.error('Error rejecting document:');
        }
    };

    return (
        <div className='documents-container'>
            <div className="docs-head">
                <h2 className="heading-xx-large">Documents</h2>
                <div className="upload-btn" style={{ marginRight: "20px" }}>
                    <input 
                        id="file-upload" 
                        type="file" 
                        onChange={onFileChange} 
                        disabled={loader}  
                    />
                    <button 
                        className='upload-button' 
                        onClick={onFileUpload} 
                        disabled={loader || !selectedFile}  
                    >
                        Upload!
                    </button>
                    {loader && <img className='dual-ball' src={DualBall} alt="Dual Ball" width="70" height="70" />}
                </div>
            </div>
            <hr />
            <TableContainer component={Paper} sx={{ width: "95%", margin: "auto" }}>
                <Table aria-label="documents table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: "20px" }}><strong>Name</strong></TableCell>
                            <TableCell sx={{ fontSize: "20px" }}><strong>Date</strong></TableCell>
                            <TableCell sx={{ fontSize: "20px" }}><strong>Status</strong></TableCell>
                            {(user.role === 'HR' || user.role === 'Executive') && <TableCell sx={{ fontSize: "20px" }}><strong>Actions</strong></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((doc) => (
                            <TableRow key={doc._id}>
                                <TableCell>
                                    <button onClick={() => window.open(doc.fileUrl, "_blank")} className='upload-button'>
                                        {doc.fileName}
                                    </button>
                                </TableCell>
                                <TableCell>{new Date(doc.uploadTime).toLocaleString()}</TableCell>
                                <TableCell>{doc.status}</TableCell>
                                {(user.role === 'HR' || user.role === 'Executive') && doc.status === 'Pending' && (
                                    <TableCell>
                                        <IconButton onClick={() => handleApprove(doc._id)} color="success">
                                            <DoneIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleReject(doc._id)} color="error">
                                            <CloseIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Documents;
