// AddEmployee.js
import React, { useState, useEffect } from "react";
import '../SearchContainer.css';


import "./RequestedLeaves.css";
import "animate.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";


import {
  TextField,
  Button,
  DialogActions,
  DialogContent,
  TableContainer,
  IconButton,
  Snackbar,
  Alert,
  ButtonGroup,
  Pagination,
  Select,
  MenuItem,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Leaves = (props) => {
  const user = props.user;
  const [showLeaves, setShowLeaves] = useState(true);
  const [reportList, setReportList] = useState([]);
  const [reportListArray, setReportListArray] = useState([]);
  const [filteredReports, setfilteredReports] = useState([]);
  const [leaveRows, setLeaveRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState({});
  const [leaveToggle, setLeaveToggle] = useState("n");
  const [searchName, setSearchName] = useState('');
  const [searchLeaveType, setSearchLeaveType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);


  const [searchDate, setSearchDate] = useState(dayjs());
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState('pending');


  const handleToggle = (tab) => {
    setShowLeaves(tab === 'leaves');
  };

  const handleSearchByName = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchName(searchValue);

    const filteredReports = leaveRows.filter(report =>
      report.name.toLowerCase().includes(searchValue)
    );
    setfilteredReports(filteredReports);
  };

  const handleSearchByLeaveType = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchLeaveType(searchValue);

    const filtered = leaveRows.filter(report =>
      report.leaveType.toLowerCase().includes(searchValue)
    );
    setfilteredReports(filtered);
  };

  const fetchPendingNotifications = async () => {
    try {
      const response = await fetch(`https://hr-backend-72v1.onrender.com/pendingnotifications?page=${currentPage}`);
      const { data, total } = await response.json();
      setNotifications(data);
      setTotalPages(Math.ceil(total / 10));
    } catch (error) {
      console.error("Error fetching pending notifications:");
    }
  };

  const fetchApprovedNotifications = async () => {
    try {
      const response = await fetch(`https://hr-backend-72v1.onrender.com/approvednotifications?page=${currentPage}`);
      const { data, total } = await response.json();
      setNotifications(data);
      setTotalPages(Math.ceil(total / 10));
    } catch (error) {
      console.error("Error fetching approved notifications:");
    }
  };


  useEffect(() => {
    if (status === 'pending') {
      fetchPendingNotifications();
    } else if (status === 'approved') {
      fetchApprovedNotifications();
    }
  }, [currentPage, status]);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setCurrentPage(1); // Reset to the first page when changing status
  };


  const approveRequest = async (request) => {
    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/approveRequest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: request._id }),
      });
      const data = await response.json();
      if (data.success) {
        setAlertMessage('Request accepted');
        setShowAlert(true);
        if (status === 'pending') {
          fetchPendingNotifications();
        } else if (status === 'approved') {
          fetchApprovedNotifications();
        }
      } else {
        alert('Error approving request');
      }
    } catch (error) {
      console.error('Error approving request:');
    }
  };

  // Reject a request (delete)
  const rejectRequest = async (id) => {
    try {
      const response = await fetch(`https://hr-backend-72v1.onrender.com/rejectRequest/${id}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (data.success) {
        setAlertMessage('Request rejected');
        setShowAlert(true);
        if (status === 'pending') {
          fetchPendingNotifications();
        } else if (status === 'approved') {
          fetchApprovedNotifications();
        }
      } else {
        alert('Error rejecting request');
      }
    } catch (error) {
      console.error('Error rejecting request:');
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const fetchLeaves = async () => {
    try {
      const response = await fetch("https://hr-backend-72v1.onrender.com/showleaves");
      const data = await response.json();

      if (response.ok) {
        const formattedData = data.map((leave) => {
          const fromDate = new Date(leave.fromDate);
          const toDate = new Date(leave.toDate);

          // Calculate total days, ensuring that if fromDate === toDate, totalDays is 1
          const totalDays = fromDate.toDateString() === toDate.toDateString()
            ? 1
            : Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24)) + 1;

          const formattedFromDate = fromDate.toLocaleDateString("en-US");
          const formattedToDate = toDate.toLocaleDateString("en-US");

          return {
            ...leave,
            dateRange: `${formattedFromDate} | ${formattedToDate}`,
            totalDays: totalDays,
          };
        });

        // console.log("Response data:", formattedData);
        setLeaveRows(formattedData);
      } else {
        console.error("Failed to fetch leaves:");
      }
    } catch (error) {
      console.error("Error fetching leaves:");
    }
  };


  useEffect(() => {
    fetchLeaves();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (reason) => {
    setReason(reason);
    setOpen(true);
  };
  const leaveAcceptHandler = (role, email, leaveId, fromDate, toDate, leaveType) => {
    handleLeaveUpdate(role, email, "Accepted", leaveId, fromDate, toDate, leaveType);
  };
  const leaveRejectHandler = (role, email, leaveId, fromDate, toDate, leaveType) => {
    handleLeaveUpdate(role, email, "Rejected", leaveId, fromDate, toDate, leaveType);
  };

  const handleLeaveUpdate = async (role, email, status, leaveId, fromDate, toDate, leaveType) => {
    try {
      // Make the POST request to the backend
      const response = await fetch(`https://hr-backend-72v1.onrender.com/update-leave-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          role,
          email,
          status: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(), // Capitalize status
          leaveId,
          fromDate,
          toDate,
          leaveType,
        }),
      });

      // Check if the response is not successful
      if (!response.ok) {
        // Parse the error message from the response JSON
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update leave status");
      }

      // Parse the successful response data
      const data = await response.json();
      // console.log("Leave status updated:", data);

      // Fetch the updated list of leaves after the change
      fetchLeaves();

      // Show success alert to the user
      alert("Leave State Updated");

    } catch (error) {
      // Log the error and show an alert with the error message
      console.error("Error updating leave status:");
      alert(error.message);  // Display the error message from the backend
    }
  };


  return (
    <div>
      <div style={{ position: "relative", display: "flex", justifyContent: "center", marginBottom: "20px", gap: "20px", zIndex: "1000" }}>
        <Button variant={showLeaves ? "contained" : "outlined"} onClick={() => handleToggle('leaves')}>
          Leaves
        </Button>
        <Button variant={!showLeaves ? "contained" : "outlined"} onClick={() => handleToggle('notifications')}>
          Notifications
        </Button>
      </div>

      {showLeaves ? (
        <>
          {/* <div className="search-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div class="night">
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
            </div>

            <div className="search-fields" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "50px", width: "100%" }}>
              <TextField
                sx={{
                  backgroundColor: "#4484FF",
                  borderRadius: "10px",
                  '& .MuiInputLabel-root': {
                    color: '#ffad3d',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ffad3d',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ffad3d',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ffad3d',
                    },
                    '& input': {
                      color: '#ffad3d',
                    },
                  },
                }}
                onChange={handleSearchByName}
                className="search-input"
                label="Search By Name"
                variant="outlined"
              />

              <TextField
                sx={{
                  backgroundColor: "#4484FF",
                  borderRadius: "10px",
                  '& .MuiInputLabel-root': {
                    color: '#ffad3d',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ffad3d',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ffad3d',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ffad3d',
                    },
                    '& input': {
                      color: '#ffad3d',
                    },
                  },
                }}
                onChange={handleSearchByLeaveType}
                value={searchLeaveType}
                label="Search By Leave Type"
                variant="outlined"
              />

            </div>
          </div> */}
          <TableContainer component={Paper} className="leaveContainer">
            <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
              LEAVE REQUESTS
            </h4>
            <Table sx={{ minWidth: 650 }} className="leaveTable">
              <TableHead>
                <TableRow>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Employee</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Leave Type</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Date</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Days</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">HR Status</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Exec. Status</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Reason</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells" align="center">
                    <span className="myTableHeadSpan">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(filteredReports.length > 0 ? filteredReports : leaveRows).map((leave) => (
                  <TableRow key={leave._id}>
                    <TableCell sx={{ fontSize: "large" }}>{leave.name}</TableCell>
                    <TableCell sx={{ fontSize: "large" }}>{leave.leaveType}</TableCell>
                    <TableCell sx={{ fontSize: "large" }}>{leave.dateRange}</TableCell>
                    <TableCell sx={{ fontSize: "large" }}>{leave.totalDays}</TableCell>

                    <TableCell sx={{ fontSize: "large" }}>
                      {leave.hrstatus === "Accepted" || leave.hrstatus === "Rejected"
                        ? leave.hrstatus
                        : "Pending"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "large" }}>
                      {leave.executivestatus === "Accepted" || leave.executivestatus === "Rejected"
                        ? leave.executivestatus
                        : leave.executivestatus}
                    </TableCell>

                    <TableCell sx={{ fontSize: "large" }}>
                      <Button
                        onClick={() =>
                          handleClickOpen({
                            name: leave.name,
                            leaveType: leave.leaveType,
                            dateRange: leave.dateRange,
                            requestedDays: leave.totalDays,
                            hrstatus: leave.hrstatus,
                            executivestatus: leave.executivestatus,
                            reason: leave.reason,
                          })
                        }
                      >
                        View Reason
                      </Button>
                    </TableCell>

                    <TableCell align="center">
                      {user.role == 'HR' && leave.hrstatus === 'Pending' ? (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button onClick={() => leaveAcceptHandler('hr', leave.email, leave._id, leave.fromDate, leave.toDate, leave.leaveType)}>
                            <DoneIcon sx={{ color: "#49ff06", fontSize: "x-large" }} />
                          </Button>
                          <Button onClick={() => leaveRejectHandler('hr', leave.email, leave._id, leave.fromDate, leave.toDate, leave.leaveType)}>
                            <CloseIcon sx={{ color: "#E76C6C", fontSize: "x-large" }} />
                          </Button>
                        </div>
                      ) : null}
                      {user.role == 'Executive' && leave.hrstatus === 'Accepted' && leave.executivestatus === 'Pending' ? (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button onClick={() => leaveAcceptHandler('executive', leave.email, leave._id, leave.fromDate, leave.toDate, leave.leaveType)}>
                            <DoneIcon sx={{ color: "#49ff06", fontSize: "x-large" }} />
                          </Button>
                          <Button onClick={() => leaveRejectHandler('executive', leave.email, leave._id, leave.fromDate, leave.toDate, leave.leaveType)}>
                            <CloseIcon sx={{ color: "#E76C6C", fontSize: "x-large" }} />
                          </Button>
                        </div>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </TableContainer>
        </>
        
      ) : (
        <TableContainer component={Paper} className="notificationContainer">
          <h4 className="myTableHeader">NOTIFICATIONS</h4>
          <ButtonGroup color="primary" aria-label="outlined primary"
            style={{ display: "flex", flexDirection: "row", gap: "50px", marginBottom: "20px" , marginTop: "20px" }}>
            <Button
              variant={status === 'pending' ? "contained" : "outlined"}
              onClick={() => handleStatusChange('pending')}
            >
              Pending
            </Button>
            <Button
              variant={status === 'approved' ? "contained" : "outlined"}
              onClick={() => handleStatusChange('approved')}
            >
              Approved
            </Button>
          </ButtonGroup>

          <Table sx={{ minWidth: 650 }} className="notificationTable">
            <TableHead>
              <TableRow>
                <TableCell className="myTableHeadCells"><span className="myTableHeadSpan">Name</span></TableCell>
                <TableCell className="myTableHeadCells"><span className="myTableHeadSpan">Field</span></TableCell>
                <TableCell className="myTableHeadCells"><span className="myTableHeadSpan">Requested Change</span></TableCell>
                <TableCell className="myTableHeadCells"><span className="myTableHeadSpan">Status</span></TableCell>
                {(user.role === 'HR' || user.role === 'Executive') && <TableCell className="myTableHeadCells">
                  <span className="myTableHeadSpan">Actions</span>
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((request) => (
                <TableRow key={request._id}>
                  <TableCell sx={{ fontSize: "larger" }}>{request.name}</TableCell>
                  <TableCell sx={{ fontSize: "larger" }}>{request.field}</TableCell>

                  {/* Conditionally render the value or the MUI button */}
                  <TableCell>
                    {request.value.includes('https') ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => window.open(request.value, '_blank')}
                      >
                        Open Link
                      </Button>
                    ) : (
                      request.value
                    )}
                  </TableCell>

                  {request.status === 'pending' ? (
                    <TableCell sx={{ fontSize: "medium" }}><span className="pending">{request.status}</span></TableCell>
                  ) : (
                    <TableCell sx={{ fontSize: "medium" }}><span className="accepted">{request.status}</span></TableCell>
                  )}

                  {(user.role === 'HR' || user.role === 'Executive') && (
                    <TableCell sx={{ fontSize: "larger" }}>
                      <div style={{ display: 'flex', flexDirection: "row", gap: "18px" }}>
                        {request.status === 'pending' ? (
                          <>
                            <IconButton
                              color="success"
                              onClick={() => approveRequest(request)}
                            >
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => rejectRequest(request._id)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        ) : (
                          'Already Taken'
                        )}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", margin: "20px 0" }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              color="primary"
            />

            <Select
              value={currentPage}
              onChange={e => setCurrentPage(Number(e.target.value))}
              sx={{ minWidth: 120 }}
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 300 },
                },
              }}
            >
              {Array.from({ length: totalPages }, (_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  Page {index + 1}
                </MenuItem>
              ))}
            </Select>
          </div>

        </TableContainer>

      )}



      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogActions
          sx={{ position: "absolute", right: 0, padding: "15px 5px" }}
        >
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogActions>
        <div className="dialogTable">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ height: "50px" }}>
                <TableCell
                  sx={{ fontSize: "larger" }}
                  className="dialogHeading"
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ fontSize: "larger" }}
                  className="dialogHeading"
                >
                  Leave Type
                </TableCell>
                <TableCell
                  sx={{ fontSize: "larger" }}
                  className="dialogHeading"
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{ fontSize: "larger" }}
                  className="dialogHeading"
                >
                  Days
                </TableCell>
                <TableCell
                  sx={{ fontSize: "larger" }}
                  className="dialogHeading"
                  align="right"
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className="dialogtext"
                  sx={{ paddingLeft: "15px !important" }}
                >
                  {reason.name}
                </TableCell>
                <TableCell
                  className="dialogtext"
                  sx={{ paddingLeft: "20px !important" }}
                >
                  {reason.leaveType}
                </TableCell>
                <TableCell
                  className="dialogtext"
                  sx={{ paddingLeft: "20px !important" }}
                >
                  {reason.dateRange}
                </TableCell>
                <TableCell
                  className="dialogtext"
                  sx={{ paddingLeft: "20px !important" }}
                >
                  {reason.totalDays}
                </TableCell>
                <TableCell className="dialogtext" align="right">
                  {reason.status}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <DialogTitle className="dialogHeading">Reason</DialogTitle>
        <DialogContent className="dialogtextReport">
          {reason.reason}
        </DialogContent>
      </Dialog>

      <Snackbar
        open={showAlert}
        autoHideDuration={5000}  // Alert disappears after 4 seconds
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity={alertMessage.includes('accepted') ? 'success' : 'error'}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Leaves;
