// src/Sidebar.js
import React , {useState , useEffect} from "react";
import logo from "../../../utilities/logo-png.png";
import "./sidebarr.css";
import MoreTimeIcon from '@mui/icons-material/MoreTime';

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";


const Sidebar = (props) => {
  const user = props.user;
  const myProfileClass = props.myProfileToggle ? "activeNav" : "deactivateNav";
  const myDashboardClass = props.timerShowToggle ? "activeNav" : "deactivateNav";
  const eventsClass = props.eventsToggle ? "activeNav" : "deactivateNav";
  const requestLeaveClass = props.requestLeaveToggle ? "activeNav" : "deactivateNav";
  const employeeCountdownClass = props.employeesCountdownToggle ? "activeNav" : "deactivateNav";
  const [Employee, setEmployee] = useState(null);


  useEffect(() => {//
    const loginEmployee = async () => {
      try {
        const response = await fetch(`https://hr-backend-72v1.onrender.com/loginemployee?email=${user.email}`);
        const data = await response.json();

        if (response.ok) {
          // console.log('Login successful:', data);
          setEmployee(data);
        } else {
          console.error('Login failed:', data.message);
        }
      } catch (error) {
        console.error('Error during login:', error);
      }
    };

    loginEmployee();
  }, [user.email]);


  return (
    <div className="sidebar-space">
      <div className="sidebar">
        <div className="side-content">
          <div className="logo">
            <img
              src={logo}
              width="120"
              height="120"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            <span>Growth Guild</span>
          </div>
          <div className="buttons">
            {/* EMPTY */}
            <button
              className={`navButton ${myDashboardClass}`}
              onClick={props.onShowTimer}
            >
              <DashboardOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Dashboard
            </button>
            <button
              className={`navButton ${myProfileClass}`}
              onClick={props.onShowProfile}
            >
              <PersonOutlineIcon sx={{ marginTop: "-5px" }} />
              &nbsp; My Profile
            </button>
            <button
              className={`navButton ${eventsClass}`}
              onClick={props.onShowEvents}
            >
              <CelebrationOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Upcoming Events
            </button>
            {/* https://hr-backend-72v1.onrender.com */}
            <button
              className={`navButton ${requestLeaveClass}`}
              onClick={props.onShowRequestLeave}
            >
              <AddReactionOutlinedIcon sx={{ marginTop: "-5px" }} />
              &nbsp; Request A Leave
            </button>
            {Employee && Employee.manager == 'yes' &&
              <button
                className={`navButton ${employeeCountdownClass}`}
                onClick={props.onShowEmployeesCountdown}
              >
                <MoreTimeIcon sx={{ marginTop: "-5px" }} />
                &nbsp; Countdowns
              </button>
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
